<template>
    <div class="location-back">
        <!--环图片-->
        <div class="img-box">
            <div class="prop-up"></div>
            <img src="@/assets/img/circle.png" class="circle" alt="">
        </div>
        <!--题目-->
        <div class="subject">
            <div>{{$t('enterTitle3')}}</div>
        </div>
        <!--选项-->
        <div style="width:220px;margin:calc(0.035 * var(--body-height)) auto 0;">
            <el-checkbox-group v-model="checkedIntents" :max="3">
                <el-checkbox :label="$t('intent1')"></el-checkbox>
                <el-checkbox :label="$t('intent2')"></el-checkbox>
                <el-checkbox :label="$t('intent3')"></el-checkbox>
                <el-checkbox :label="$t('intent4')"></el-checkbox>
                <el-checkbox :label="$t('intent5')"></el-checkbox>
                <el-checkbox :label="$t('intent6')"></el-checkbox>
                <el-checkbox :label="$t('intent7')"></el-checkbox>
                <el-checkbox :label="$t('intent8')"></el-checkbox>
                <div style="display:flex;align-items: baseline">
                    <el-checkbox :label="$t('intent9')"></el-checkbox>
                    <el-input v-model="otherIntent"
                              :disabled="inputDisabled"
                              maxlength="10"
                              :placeholder="this.$t('outLocate9Specify')"
                              style="width:calc(0.40 * var(--body-width));">
                    </el-input>
                </div>
            </el-checkbox-group>
        </div>
        <!--题目跳转-->
        <div class="subject-jump">
            <span @click="prevClick" class="prev-btn">{{$t('preQues')}}</span>
            <el-button @click="nextClick"
                       :disabled="countNumber!=0"
                       style="width:160px;"
                       type="primary">{{$t('nextQues')}} <i class="icon-right"></i> ({{countNumber}}s)
            </el-button>
        </div>
    </div>

</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        name: "garden_enter3",
        data() {
            return {
                //次数
                checkedIntents: [],
                otherIntent: '',
                timer: null,
                countNumber: 0
            }
        },
        computed: {
            ...mapGetters([
                'enter3Answer'
            ]),
            inputDisabled() {
                return this.checkedIntents.length === 3 && !this.checkedIntents.includes(this.$t('intent9'))
            }
        },
        watch: {
            '$route': {
                immediate: true, // 组件创建时立即触发
                handler(newVal, oldVal) {
                    this.$store.commit('SET_ANSWER_PERCENT', 100 / 21)
                }
            }
        },
        mounted() {
            this.countdown()
            this.init()
        },
        methods: {

            /**
             * 初始化
             */
            init() {
                let copyArr = JSON.parse(JSON.stringify(this.enter3Answer))
                let len = copyArr.length;
                if (len > 1) {
                    let char = copyArr.splice(len - 1, 1)
                    if (char[0] === '无') {
                        this.otherIntent = ''
                    } else {
                        this.otherIntent = char[0]
                    }
                }
                this.checkedIntents = copyArr
            },

            /**
             * 上一题
             */
            prevClick() {
                this.$router.push('/enter2')
            },

            /**
             * 下一题
             */
            nextClick() {
                if (this.checkedIntents.length < 1) {
                    this.$toast(this.$t('selectOne'));
                    return;
                }
                //选择其他时
                if (this.checkedIntents.includes(this.$t('intent9'))) {
                    if (this.otherIntent == '' || this.otherIntent == '无') {
                        this.$toast(this.$t('chooseOtherTip'));
                        return;
                    } else {
                        let reg = /^[\u4e00-\u9fa5_a-zA-Z0-9]+$/
                        if (!reg.test(this.otherIntent)) {
                            this.$toast(this.$t('wordReg'));
                            return;
                        }
                    }
                } else {
                    this.otherIntent = ''
                }

                //保存答案
                let arr = JSON.parse(JSON.stringify(this.checkedIntents))
                if (this.checkedIntents.includes(this.$t('intent9'))) {
                    arr.push(this.otherIntent)
                } else {
                    arr.push('无')
                }
                this.$store.commit('SET_ENTER3_ANSWER', arr)
                this.$router.push('/enter4')
            },

            /**
             * 验证码倒计时
             */
            countdown() {
                let that = this;
                this.countNumber = 2;
                if (this.timer) {
                    clearInterval(this.timer);
                }
                this.timer = setInterval(function () {
                    if (that.countNumber === 0) {
                        clearInterval(this.timer);
                    } else {
                        that.countNumber--;
                    }
                }, 1000);
            }
        }
    }
</script>

<style scoped lang="less">
    .location-back {
        height: 100%;
    }

</style>