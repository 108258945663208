/**
 * axios封装
 * 请求拦截、响应拦截、错误统一处理
 */
import axios from 'axios'
import store from '../store'
import {Notification} from 'element-ui'
import {message} from '@/rewrite/message.js';
import {getToken, removeToken, removeUserId} from '@/user_info/auth'

/**
 * 提示函数
 * 禁止点击蒙层、显示一秒后关闭
 */
const tip = msg => {
    Notification({
        title: '成功',
        message: msg,
        position: 'bottom-right',
        duration: 2000,
        type: 'success'
    })
}

const errorTip = msg => {
    message({
        message: msg,
        type: 'error'
    })
}

// 创建axios实例
const instance = axios.create({timeout: 1000 * 120})
// 设置post请求头
instance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'

/**
 * 请求拦截器
 * 每次请求前，如果存在token则在请求头中携带token
 */
instance.interceptors.request.use(
    config => {
        config.headers.common['user-token'] = store.state.user.userToken
        return config
    },
    error => Promise.error(error))

/**
 * 响应拦截器
 */
instance.interceptors.response.use(res => {
        // 请求成功
        if (res.data.code === '0' && res.data.status === true) {
            // if (res.config.method !== 'get') {
            //     tip(res.data.message)
            // }
            return Promise.resolve(res)
        } else {
            if (res.data.code === '20001') {
                removeUserId()
                removeToken()
                //网页版
                // window.location.href = '/login'
                // app版
                window.parent.postMessage({ type: 'TOKEN_EXPIRED' }, '*');
            }
            errorTip(res.data.message);
            return Promise.reject(res)
        }
    },
    // 请求失败
    error => {
        const {response} = error
        if (response) {
            errorTip(response.data.message);
            return Promise.reject(response)
        } else {
            // 处理断网的情况
            // eg:请求超时或断网时，更新state的network状态
            // network状态在app.vue中控制着一个全局的断网提示组件的显示隐藏
            // 关于断网组件中的刷新重新获取数据，会在断网组件中说明
            if (!window.navigator.onLine) {
                store.commit('changeNetwork', false)
            } else {
                return Promise.reject(error)
            }
        }
    })

export default instance
