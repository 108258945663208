<template>
    <div>
        <!--题目-->
        <div class="subject">
            <div>{{titleName}}</div>
        </div>
        <!--选项-->
        <div style="width:115px;margin:calc(0.073 * var(--body-height)) auto 0;">
            <el-radio-group v-model="mentalAnswer">
                <el-radio :label="item.answer" v-for="item in answerOpt"></el-radio>
            </el-radio-group>
        </div>
        <!--题目跳转-->
        <div class="subject-jump">
            <span @click="prevClick" class="prev-btn"><i class="icon-left"></i> {{$t('preQues')}}</span>
            <el-button @click="nextClick"
                       :disabled="countNumber!=0"
                       style="width:160px;"
                       type="primary">{{$t('nextQues')}} <i class="icon-right"></i> ({{countNumber}}s)
            </el-button>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        name: "radio_section",
        data() {
            return {
                //心里单选题答案
                mentalAnswer: '',
                //题目
                titleName: '',
                //选项
                answerOpt: [],

                titleId: -1,
                titleIndex: 2,
                timer: null,
                countNumber: 0,
                //心理单选题目
                mentalSubject: [
                    {
                        id: 3,
                        title: this.$t('mentalQue4'),
                        option: [
                            {val: 1, answer: this.$t('yes')},
                            {val: 2, answer: this.$t('no')},
                            {val: 3, answer: this.$t('notSure')}]
                    },
                    {
                        id: 4,
                        title: this.$t('mentalQue5'),
                        option: [
                            {val: 1, answer: this.$t('yes')},
                            {val: 2, answer: this.$t('no')},
                            {val: 3, answer: this.$t('notSure')}]
                    },
                    {
                        id: 5,
                        title: this.$t('mentalQue6'),
                        option: [
                            {val: 1, answer: this.$t('veryCold')},
                            {val: 2, answer: this.$t('cold')},
                            {val: 3, answer: this.$t('cool')},
                            {val: 4, answer: this.$t('modTem')},
                            {val: 5, answer: this.$t('warm')},
                            {val: 6, answer: this.$t('hot')},
                            {val: 7, answer: this.$t('veryHot')}],
                    },
                    {
                        id: 6,
                        title: this.$t('mentalQue7'),
                        option: [
                            {val: 1, answer: this.$t('veryCloudy')},
                            {val: 2, answer: this.$t('cloudy')},
                            {val: 3, answer: this.$t('mostCloudy')},
                            {val: 4, answer: this.$t('partCloudy')},
                            {val: 5, answer: this.$t('partSunny')},
                            {val: 6, answer: this.$t('mostSunny')},
                            {val: 7, answer: this.$t('verySunny')}],
                    },
                    {
                        id: 7,
                        title: this.$t('mentalQue8'),
                        option: [
                            {val: 1, answer: this.$t('veryHumid')},
                            {val: 2, answer: this.$t('humid')},
                            {val: 3, answer: this.$t('moist')},
                            {val: 4, answer: this.$t('modeMoi')},
                            {val: 5, answer: this.$t('slightDry')},
                            {val: 6, answer: this.$t('dry')},
                            {val: 7, answer: this.$t('veryDry')}],
                    }
                ]
            }
        },
        computed: {
            ...mapGetters([
                'mentalRadioAnswer',
                'healthFlag'
            ]),
        },
        watch: {
            '$route.query': {
                immediate: true, // 组件创建时立即触发
                handler(newVal, oldVal) {
                    //计时器
                    this.countdown()
                    this.titleId = newVal.id
                    for (let i = 0; i < this.mentalSubject.length; i++) {
                        if (this.titleId == this.mentalSubject[i].id) {
                            this.titleIndex = i + 2
                            this.$store.commit('SET_ANSWER_PERCENT', (12 + this.titleIndex) * 100 / 21)
                            this.titleName = this.mentalSubject[i].title
                            this.answerOpt = this.mentalSubject[i].option
                            break;
                        }
                    }
                    for (let i = 0; i < this.mentalRadioAnswer.length; i++) {
                        if (this.titleId == this.mentalRadioAnswer[i][0]) {
                            this.mentalAnswer = this.mentalRadioAnswer[i][1]
                            break;
                        }
                    }
                }
            }
        },
        methods: {

            /**
             * 上一题
             */
            prevClick() {
                // history.back();
                if (this.titleIndex === 2) {
                    if (this.healthFlag == 0) {
                        this.$router.push({path: '/health/a', query: {id: 2}})
                    } else {
                        this.$router.push({path: '/mental/a', query: {id: 6}})
                    }
                    return;
                }
                let preIndex = this.titleIndex - 1 - 2;
                this.$router.push({
                    path: '/mental_radio/a',
                    query: {
                        id: this.mentalSubject[preIndex].id
                    }
                })
            },

            /**
             * 下一题
             */
            nextClick() {
                if (this.mentalAnswer === '') {
                    this.$toast(this.$t('selectOne'));
                    return;
                }
                //保存答案
                this.mentalRadioAnswer[this.titleIndex][1] = this.mentalAnswer
                //最后一道自然题答完后跳转到自然题目
                let len = this.mentalSubject.length
                if (this.titleIndex === len + 1) {
                    this.$router.push('/photo')
                    return;
                }
                let nextIndex = this.titleIndex + 1 - 2;
                this.$router.push({
                    path: '/mental_radio/a',
                    query: {
                        id: this.mentalSubject[nextIndex].id
                    }
                })
            },

            /**
             * 验证码倒计时
             */
            countdown() {
                let that = this;
                this.countNumber = 2;
                if (this.timer) {
                    clearInterval(this.timer);
                }
                this.timer = setInterval(function () {
                    if (that.countNumber === 0) {
                        clearInterval(this.timer);
                    } else {
                        that.countNumber--;
                    }
                }, 1000);
            }
        }
    }
</script>

<style scoped>
</style>
