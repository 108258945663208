<template>
    <div>
        <!--题目-->
        <div class="subject">
            <div>{{$t('mentalQue1')}}</div>
        </div>

        <div class="sub-title">{{$t('IFeel')}}</div>
        <!--选项-->
        <div class="slide-wrapper" style="width:calc(0.84 * var(--body-width));margin:0 auto;">
            <van-slider v-model="feelLevel"
                        :step="1"
                        :min="-10"
                        :max="10"
                        bar-height="7px"
                        active-color="#3F75A8"
                        inactive-color="#DDDDDD">
                <template #button>
                    <div class="slide-button"><span class="dot"></span></div>
                </template>
            </van-slider>
            <div class="scale">
                <span v-for="item in 21">I</span>
            </div>
            <div class="text-box">
                <span>{{feel1}}</span>
                <span>{{feel2}}</span>
            </div>
        </div>
        <!--题目跳转-->
        <div class="subject-jump">
            <span @click="prevClick" class="prev-btn">{{$t('preQues')}}</span>
            <el-button @click="nextClick"
                       :disabled="countNumber!=0"
                       style="width:160px;"
                       type="primary">{{$t('nextQues')}} <i class="icon-right"></i> ({{countNumber}}s)
            </el-button>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        name: "question",
        data() {
            return {
                feelLevel: 0,
                feel1: '',
                feel2: '',
                titleId: -1,
                titleIndex: 0,
                timer: null,
                countNumber: 0,
                slideDescribe: [
                    {id: 1, level1: this.$t('satisfied'), level2: this.$t('dissatisfied')},
                    {id: 2, level1: this.$t('unwell'), level2: this.$t('well')},
                    {id: 3, level1: this.$t('agitated'), level2: this.$t('calm')},
                    {id: 4, level1: this.$t('relaxed'), level2: this.$t('tense')},
                    {id: 5, level1: this.$t('tired'), level2: this.$t('awake')},
                    {id: 6, level1: this.$t('energetic'), level2: this.$t('listless')},
                ]
            }
        },
        computed: {
            ...mapGetters([
                'sliderAnswer',
                'healthFlag',
                'aggressionFlag'
            ]),
        },
        watch: {
            '$route.query': {
                immediate: true, // 组件创建时立即触发
                handler(newVal, oldVal) {
                    //计时器
                    this.countdown()
                    this.titleId = newVal.id
                    for (let i = 0; i < this.slideDescribe.length; i++) {
                        if (this.titleId == this.slideDescribe[i].id) {
                            this.titleIndex = i
                            this.$store.commit('SET_ANSWER_PERCENT', (6 + this.titleIndex) * 100 / 21)
                            this.feel1 = this.slideDescribe[i].level1
                            this.feel2 = this.slideDescribe[i].level2
                            break;
                        }
                    }
                    for (let i = 0; i < this.sliderAnswer.length; i++) {
                        if (this.titleId == this.sliderAnswer[i][0]) {
                            this.feelLevel = this.sliderAnswer[i][1]
                            break;
                        }
                    }
                }
            }
        },
        methods: {

            /**
             * 上一题
             */
            prevClick() {
                // history.back();
                if (this.titleIndex === 0) {
                    this.$router.push({
                        path: '/nature/a',
                        query: {
                            id: 3
                        }
                    })
                    return;
                }
                let preIndex = this.titleIndex - 1;
                this.$router.push({
                    path: '/mental/a',
                    query: {
                        id: this.slideDescribe[preIndex].id
                    }
                })
            },

            /**
             * 下一题
             */
            nextClick() {
                //保存答案
                this.sliderAnswer[this.titleIndex][1] = this.feelLevel
                //滑块题答完后跳转到单选题目或拍照题
                let len = this.slideDescribe.length
                if (this.titleIndex === len - 1) {
                    if (this.healthFlag == 0) { //健康题未回答
                        this.$router.push({
                            path: '/health/a',
                            query: {
                                id: 1
                            }
                        })
                    } else { //健康题已回答
                        if (this.aggressionFlag == 0) { //攻击性题未回答
                            this.$router.push({
                                path: '/mental_radio/a',
                                query: {
                                    id: 3
                                }
                            })
                        } else { //攻击性题已回答
                            this.$router.push('/photo')
                        }
                    }
                    return;
                }
                let nextIndex = this.titleIndex + 1;
                this.$router.push({
                    path: '/mental/a',
                    query: {
                        id: this.slideDescribe[nextIndex].id
                    }
                })
            },

            /**
             * 验证码倒计时
             */
            countdown() {
                let that = this;
                this.countNumber = 2;
                if (this.timer) {
                    clearInterval(this.timer);
                }
                this.timer = setInterval(function () {
                    if (that.countNumber === 0) {
                        clearInterval(this.timer);
                    } else {
                        that.countNumber--;
                    }
                }, 1000);
            }
        }
    }
</script>

<style scoped lang="less">

    .sub-title {
        font-size: 16px;
        margin-top: calc(0.036 * var(--body-height));
        margin-left: calc(0.08 * var(--body-width));
        margin-bottom: calc(0.168 * var(--body-height));
    }

    .slide-wrapper {

        .slide-button {
            width: 19px;
            height: 19px;
            text-align: center;
            background-color: #fff;
            border-radius: 50%;
            box-shadow: 0 2px 6px 0 rgba(133, 166, 199, 0.6);
            display: flex;
            align-items: center;
            justify-content: center;

            .dot {
                display: inline-block;
                width: 5px;
                height: 5px;
                border-radius: 50%;
                background-color: #3F75A8;
            }
        }

        .scale {
            display: flex;
            justify-content: space-between;
            margin-bottom: calc(0.03 * var(--body-height));

            span {
                font-size: 10px;
                color: #3F75A8;
            }
        }

        .text-box {
            font-size: 14px;
            color: #333333;

            display: flex;
            justify-content: space-between;
            align-items: center;
        }

    }

</style>
