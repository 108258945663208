<template>
    <div class="location-back">
        <!--环图片-->
        <div class="img-box">
            <div class="prop-up"></div>
            <img src="@/assets/img/circle.png" class="circle" alt="">
        </div>
        <!--题目-->
        <div class="subject">
            <div>{{$t('adviceTitle')}}</div>
        </div>
        <div class="advice-box">
            <div class="description">{{$t('adviceTip')}}</div>
            <el-input v-model="adviceText"
                      type="textarea"
                      :rows="4"
                      resize="none"
                      :placeholder="$t('writeAdvice')"
                      maxlength="50">
            </el-input>
        </div>
        <!--题目跳转-->
        <div class="subject-jump">
            <span @click="prevClick" class="prev-btn"><i class="icon-left"></i> {{$t('preQues')}}</span>
            <el-button @click="finish"
                       :disabled="countNumber!=0"
                       :loading="finishLoading"
                       style="width:160px;"
                       type="primary">{{$t('finish')}}（{{countNumber}}s）
            </el-button>
        </div>
    </div>

</template>

<script>
    import {mapGetters} from "vuex";
    import api from "@/api";

    export default {
        name: "index",
        data() {
            return {
                adviceText: '',
                timer: null,
                countNumber: 0,
                finishLoading: false
            }
        },
        computed: {
            ...mapGetters([
                'userName',
                'userId',
                'lat',
                'lon',
                'location1Answer',
                'location2Answer',
                'location3Answer',
                'gardenAnswer',
                'enter1Answer',
                'enter2Answer',
                'enter3Answer',
                'enter4Answer',
                'enter5Answer',
                'leave1Answer',
                'location4Answer',
                'natureAnswerArr',
                'sliderAnswer',
                'mentalRadioAnswer',
                'photoFile',
                'audioFile'
            ]),
        },
        watch: {
            '$route': {
                immediate: true, // 组件创建时立即触发
                handler(newVal, oldVal) {
                    this.$store.commit('SET_ANSWER_PERCENT', 100)
                }
            }
        },
        mounted() {
            this.countdown()
        },
        methods: {

            /**
             * 保存所有答案
             */
            saveAllAnswer() {
                let strLocate = ''
                if (this.location2Answer[0] === '') {
                    if (this.location3Answer[0] == this.$t('outLocate9')) {
                        strLocate = this.location3Answer[0] + '-' + this.location3Answer[1]
                    } else {
                        strLocate = this.location3Answer[0]
                    }
                } else {
                    if (this.location2Answer[0] == this.$t('inLocate7')) {
                        strLocate = this.location2Answer[0] + '-' + this.location2Answer[1]
                    } else {
                        strLocate = this.location2Answer[0]
                    }
                }
                let enter3Str = this.arrToStr(this.enter3Answer,this.$t('intent9'))
                let enter4Str = this.arrToStr(this.enter4Answer,this.$t('trans7'))
                let result = [
                    this.location1Answer, strLocate,
                    this.gardenAnswer, this.enter1Answer, this.enter2Answer,
                    enter3Str, enter4Str,
                    this.enter5Answer, this.leave1Answer,
                    this.location4Answer,
                    this.natureAnswerArr[0][1], this.natureAnswerArr[1][1], this.natureAnswerArr[2][1],
                    this.sliderAnswer[0][1], this.sliderAnswer[1][1], this.sliderAnswer[2][1],
                    this.sliderAnswer[3][1], this.sliderAnswer[4][1], this.sliderAnswer[5][1],
                    this.mentalRadioAnswer[0][1], this.mentalRadioAnswer[1][1],
                    this.mentalRadioAnswer[2][1], this.mentalRadioAnswer[3][1],
                    this.mentalRadioAnswer[4][1], this.mentalRadioAnswer[5][1],
                    this.mentalRadioAnswer[6][1]
                ]

                let that = this
                api.questionnaire.saveAnswer(that.lat, that.lon, JSON.parse(JSON.stringify(result)), that.userId, that.photoFile, that.audioFile).then(res => {
                    that.finishLoading = false
                    that.$store.commit('SET_RECORD_ID', res.data.result[0])
                    that.$router.push('/end')
                }).catch(err => {
                    that.finishLoading = false
                })
            },

            /**
             * 数组转字符串 ['','','',''] => ''/''/''
             */
            arrToStr(arr, otherWord) {
                let len = arr.length
                let str = ''
                for (let i = 0; i < len - 1; i++) {
                    if (arr[i] === otherWord) {
                        str = str + arr[i] + '-' + arr[len - 1] + '/'
                        continue
                    }
                    str = str + arr[i] + '/'
                }
                return str;
            },

            /**
             * 完成
             */
            finish() {

                let that = this
                this.finishLoading = true
                if (this.adviceText !== '') {
                    // let reg = /^[\u4e00-\u9fa5_a-zA-Z0-9]+$/
                    let reg = /[^\u4e00-\u9fa5a-zA-Z\d,\,.，。!！*%￥$？?]+/
                    if (reg.test(this.adviceText)) {
                        this.$toast(this.$t('lineReg'));
                        this.finishLoading = false
                        return;
                    }
                    api.questionnaire.getUserPhone().then(res => {
                        let phone = res.data.result.phone
                        let name = res.data.result.userName
                        api.questionnaire.saveAdvice(phone, that.adviceText, name).then(resp => {
                            that.saveAllAnswer()
                        })
                    })
                } else {
                    this.saveAllAnswer()
                }
            },

            /**
             * 上一题
             */
            prevClick() {
                this.$router.push('/audio')
            },

            /**
             * 验证码倒计时
             */
            countdown() {
                let that = this;
                this.countNumber = 2;
                if (this.timer) {
                    clearInterval(this.timer);
                }
                this.timer = setInterval(function () {
                    if (that.countNumber === 0) {
                        clearInterval(this.timer);
                    } else {
                        that.countNumber--;
                    }
                }, 1000);
            }
        }
    }
</script>

<style scoped lang="less">
    .location-back {
        height: 100%;

        .advice-box {
            width: calc(0.84 * var(--body-width));
            margin: calc(0.073 * var(--body-height)) auto 0;
        }

        .description {
            font-size: 14px;
            color: #666666;
            margin-bottom: calc(0.017 * var(--body-height));
        }
    }

</style>