import router from './router'
import store from './store'
import {
    getToken, removeToken,
    removeUserId,
} from './user_info/auth'


// 白名单
const whiteList = ['/login', '/user/login']

/**
 * 路由全局前置守卫
 */
router.beforeEach((to, from, next) => {
    if (to.path !== "/start") {
        store.dispatch('pullUserInfo').then()
    }
    if (from.path === '/end') {
        if (to.path === '/statistics') {
            next();
        } else if (to.path === '/start') {
            next();
        } else {
            next('/start')
        }
    } else if (from.path === '/statistics') {
        if (to.path !== '/start') {
            next('/start')
        } else {
            next()
        }
    } else {
        next()
    }
})

