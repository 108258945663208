<template>
    <div class="location-back">
        <!--环图片-->
        <div class="img-box">
            <div class="prop-up"></div>
            <img src="@/assets/img/circle.png" class="circle" alt="">
        </div>
        <!--题目-->
        <div class="subject">
            <div>{{$t('locateQue4')}}</div>
        </div>
        <!--选项-->
        <div style="width:112px;margin:calc(0.073 * var(--body-height)) auto 0;">
            <el-radio-group v-model="partner">
                <el-radio :label="$t('person1')"></el-radio>
                <el-radio :label="$t('person2')"></el-radio>
                <el-radio :label="$t('person3')"></el-radio>
                <el-radio :label="$t('person4')"></el-radio>
                <el-radio :label="$t('person5')"></el-radio>
                <br>
                <el-radio :label="$t('person6')"></el-radio>
            </el-radio-group>
        </div>
        <!--题目跳转-->
        <div class="subject-jump">
            <span @click="prevClick" class="prev-btn"><i class="icon-left"></i> {{$t('preQues')}}</span>
            <el-button @click="nextClick"
                       :disabled="countNumber!=0"
                       style="width:160px;"
                       type="primary">{{$t('nextQues')}} <i class="icon-right"></i> ({{countNumber}}s)
            </el-button>
        </div>
    </div>

</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        name: "step4",
        data() {
            return {
                partner: '',
                timer: null,
                countNumber: 0
            }
        },
        computed: {
            ...mapGetters([
                'location2Answer',
                'location3Answer',
                'leave1Answer',
                'enter5Answer',
                'gardenAnswer',
                'location4Answer'
            ]),
        },
        watch: {
            '$route': {
                immediate: true, // 组件创建时立即触发
                handler(newVal, oldVal) {
                    this.$store.commit('SET_ANSWER_PERCENT', 2 * 100 / 21)
                }
            }
        },
        mounted() {
            this.countdown()
            this.partner = this.location4Answer
        },
        methods: {

            /**
             * 上一题
             */
            prevClick() {
                if (this.location2Answer[0] !== '') {
                    this.$router.push('/location2')
                } else if (this.leave1Answer !== '') {
                    this.$router.push('/leave1')
                } else if (this.enter5Answer !== '') {
                    this.$router.push('/enter5')
                } else if (this.gardenAnswer !== '') {
                    this.$router.push('/garden')
                } else {
                    this.$router.push('/location3')
                }
            },

            /**
             * 下一题
             */
            nextClick() {
                if (this.partner == '') {
                    this.$toast(this.$t('selectOne'));
                    return;
                }
                //保存答案
                this.$store.commit('SET_LOCATION4_ANSWER', this.partner)
                this.$router.push({
                    path: '/nature/a',
                    query: {
                        id: 1
                    }
                })
            },

            /**
             * 验证码倒计时
             */
            countdown() {
                let that = this;
                this.countNumber = 2;
                if (this.timer) {
                    clearInterval(this.timer);
                }
                this.timer = setInterval(function () {
                    if (that.countNumber === 0) {
                        clearInterval(this.timer);
                    } else {
                        that.countNumber--;
                    }
                }, 1000);
            }
        }
    }
</script>

<style scoped lang="less">
    .location-back {
        height: 100%;
    }

</style>