<template>
    <div>
        <!--题目-->
        <div class="subject">
            <div>{{titleName}}</div>
        </div>
        <!--选项-->
        <div style="width:115px;margin:calc(0.073 * var(--body-height)) auto 0;">
            <el-radio-group v-model="natureAnswer">
                <el-radio :label="item.answer" v-for="item in answerOpt"></el-radio>
            </el-radio-group>
        </div>
        <!--题目跳转-->
        <div class="subject-jump">
            <span @click="prevClick" class="prev-btn"><i class="icon-left"></i> {{$t('preQues')}}</span>
            <el-button @click="nextClick"
                       :disabled="countNumber!=0"
                       style="width:160px;"
                       type="primary">{{$t('nextQues')}} <i class="icon-right"></i> ({{countNumber}}s)
            </el-button>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        name: "section1",
        data() {
            return {
                //自然题答案
                natureAnswer: '',
                //题目
                titleName: '',
                //选项
                answerOpt: [],

                titleId: -1,
                titleIndex: 0,
                timer: null,
                countNumber: 0,
                //自然题目
                natureSubject: [
                    {
                        id: 1,
                        title: this.$t('natureQue1'),
                        option: [
                            {val: 1, answer: this.$t('none')},
                            {val: 2, answer: this.$t('few')},
                            {val: 3, answer: this.$t('moderate')},
                            {val: 4, answer: this.$t('many')},
                            {val: 5, answer: this.$t('dense')}]
                    },
                    {
                        id: 2,
                        title: this.$t('natureQue2'),
                        option: [
                            {val: 1, answer: this.$t('yes')},
                            {val: 2, answer: this.$t('no')},
                            {val: 3, answer: this.$t('notSure')}]
                    },
                    {
                        id: 3,
                        title: this.$t('natureQue3'),
                        option: [
                            {val: 1, answer: this.$t('yes')},
                            {val: 2, answer: this.$t('no')},
                            {val: 3, answer: this.$t('notSure')}]
                    }
                ]
            }
        },
        computed: {
            ...mapGetters([
                'natureAnswerArr'
            ]),
        },
        watch: {
            '$route.query': {
                immediate: true, // 组件创建时立即触发
                handler(newVal, oldVal) {
                    //计时器
                    this.countdown()
                    this.titleId = newVal.id
                    for (let i = 0; i < this.natureSubject.length; i++) {
                        if (this.titleId == this.natureSubject[i].id) {
                            this.titleIndex = i
                            this.$store.commit('SET_ANSWER_PERCENT', (3 + this.titleIndex) * 100 / 21)
                            this.titleName = this.natureSubject[i].title
                            this.answerOpt = this.natureSubject[i].option
                            break;
                        }
                    }
                    for (let i = 0; i < this.natureAnswerArr.length; i++) {
                        if (this.titleId == this.natureAnswerArr[i][0]) {
                            this.natureAnswer = this.natureAnswerArr[i][1]
                            break;
                        }
                    }
                }
            }
        },
        methods: {

            /**
             * 上一题
             */
            prevClick() {
                // history.back();
                if (this.titleIndex === 0) {
                    this.$router.push('/location4')
                    return;
                }
                let preIndex = this.titleIndex - 1;
                this.$router.push({
                    path: '/nature/a',
                    query: {
                        id: this.natureSubject[preIndex].id
                    }
                })
            },

            /**
             * 下一题
             */
            nextClick() {
                if (this.natureAnswer === '') {
                    this.$toast(this.$t('selectOne'));
                    return;
                }
                //保存答案
                this.natureAnswerArr[this.titleIndex][1] = this.natureAnswer
                //自然题答完跳转到心理滑块题
                let len = this.natureSubject.length
                if (this.titleIndex === len - 1) {
                    this.$router.push({
                        path: '/mental/a',
                        query: {
                            id: 1
                        }
                    })
                    return;
                }
                let nextIndex = this.titleIndex + 1;
                this.$router.push({
                    path: '/nature/a',
                    query: {
                        id: this.natureSubject[nextIndex].id
                    }
                })
            },

            /**
             * 验证码倒计时
             */
            countdown() {
                let that = this;
                this.countNumber = 2;
                if (this.timer) {
                    clearInterval(this.timer);
                }
                this.timer = setInterval(function () {
                    if (that.countNumber === 0) {
                        clearInterval(this.timer);
                    } else {
                        that.countNumber--;
                    }
                }, 1000);
            }
        }
    }
</script>

<style scoped>
</style>
