<template>
    <div class="audio-back">
        <!--环图片-->
        <div class="img-box">
            <div class="prop-up"></div>
            <img src="@/assets/img/circle.png" class="circle" alt="">
        </div>
        <!--题目-->
        <div class="subject">
            <div>{{$t('recordSound')}}</div>
        </div>


        <audio id="audioPlayer" controls preload="auto" style="display:none;"></audio>

        <!--音频盒子-->
        <div class="audio-box">
            <!--音频插件-->
            <div v-show="audioUrl" class="audio-plugin">
                <i @click="playAudio" :class="[isPlay ? 'el-icon-video-pause':'el-icon-video-play']"></i>
                <el-progress :percentage="audioPercent"
                             style="width: calc(0.38 * var(--body-width));"
                             :show-text="false">
                </el-progress>
                <div class="text">00:{{formatSeconds(playTime)}} / 00:{{formatSeconds(totalDuration)}}</div>
                <!--删除音频-->
                <i @click="deleteAudio" class="el-icon-delete"></i>
            </div>
            <!--暂无音频-->
            <div v-show="!audioUrl" style="font-size:16px;">{{$t('noSound')}}</div>
        </div>

        <!--录制按钮-->
        <div class="out-ring">
            <div @click="startRecording" v-show="!isRecording" class="in-ring">
                <img src="@/assets/img/audio.png" alt="" style="height:48px;">
            </div>
            <div v-show="isRecording" @click="stopClick" class="in-ring">
                <div class="red-square"></div>
            </div>
        </div>
        <div v-show="!isRecording" class="tip">{{$t('clickToRecord')}}</div>

        <!--题目跳转-->
        <div class="subject-jump">
            <span @click="prevClick" class="prev-btn"><i class="icon-left"></i> {{$t('preQues')}}</span>
            <el-button @click="nextClick"
                       :disabled="countNumber!=0"
                       style="width:160px;"
                       type="primary">{{$t('nextQues')}} <i class="icon-right"></i> ({{countNumber}}s)
            </el-button>
        </div>
    </div>
</template>
<script>
    import {mapGetters} from "vuex";
    import api from '@/api'

    export default {
        data() {
            return {
                countNumber: 0,
                isRecording: false,
                //音频
                mediaRecorder: null,
                audioChunks: [],
                //总时长
                totalDuration: 0,
                //播放时间
                playTime: 0,
                //是否播放
                isPlay: false,
                //音频计时器
                audioTimer: null,
                //播放计时器
                playTimer: null,

                //按钮倒计时
                btnTimer: null,
            }
        },
        computed: {
            ...mapGetters([
                'userId',
                'audioFile',
                'audioUrl',
                'audioDuration'
            ]),
            //音频进度条
            audioPercent() {
                if (this.totalDuration === 0) {
                    return 0
                } else {
                    return this.playTime * 100 / this.totalDuration
                }
            }
        },
        watch: {
            '$route': {
                immediate: true, // 组件创建时立即触发
                handler(newVal, oldVal) {
                    this.$store.commit('SET_ANSWER_PERCENT', 20 * 100 / 21)
                }
            }
        },
        mounted() {
            this.countdown()
            if (this.audioFile) {
                this.showAudio(this.audioUrl);
                this.totalDuration = this.audioDuration
            }
        },
        methods: {

            /**
             * 开始录音
             */
            async startRecording() {
                if (this.audioUrl) {
                    this.$toast(this.$t('deleteAudio'))
                    return;
                }
                let that = this
                try {
                    // window.URL = window.URL || window.webkitURL
                    // navigator.getUserMedia =  navigator.getUserMedia ||navigator.webkitGetUserMedia ||
                    //     navigator.mozGetUserMedia || navigator.msGetUserMedia || navigator.mediaDevices.getUserMedia;
                    const stream = await navigator.mediaDevices.getUserMedia({
                        audio: {
                            sampleRate: 44100,
                            channelCount: 2
                        }, video: false
                    });
                    that.mediaRecorder = new MediaRecorder(stream);
                    that.mediaRecorder.ondataavailable = e => {
                        that.audioChunks.push(e.data);
                    };
                    that.mediaRecorder.onstop = () => {
                        let audioBlob = new Blob(this.audioChunks, {'type': 'audio/mp3; codecs=opus'});
                        let soundUrl = URL.createObjectURL(audioBlob);

                        //生成file对象
                        let file = new File([audioBlob], 'yinpin.mp3', {
                            type: 'application/json',
                            lastModified: Date.now()
                        })

                        //页面播放录音
                        that.showAudio(soundUrl)

                        that.$store.commit('SET_AUDIO_FILE', file)
                        that.$store.commit('SET_AUDIO_URL', soundUrl)

                        // 重置音频块数组以备下次录音
                        that.audioChunks = [];
                    };
                    that.mediaRecorder.start();
                    that.isRecording = true;

                    //记录录音总时长
                    if (that.audioTimer) {
                        clearInterval(that.audioTimer);
                    }
                    that.audioTimer = setInterval(function () {
                        if (that.totalDuration === 15 || that.isRecording === false) {
                            clearInterval(that.audioTimer);
                        } else {
                            that.totalDuration++;
                        }
                    }, 1000);

                    that.timer = setTimeout(that.stopRecording, 16000);
                } catch (err) {
                    that.$toast(that.$t('failedAudio'))
                }
            },

            /**
             * 结束录音
             */
            stopRecording() {
                this.mediaRecorder.stop();
                this.isRecording = false;
                this.$toast(this.$t('recordingEnd'))
            },

            /**
             * 停止录音点击事件
             */
            stopClick() {
                clearTimeout(this.timer);
                this.timer = null
                this.stopRecording()
            },

            /**
             * 展示音频
             */
            showAudio(Url) {
                let audioEle = document.getElementById('audioPlayer');
                audioEle.src = Url;
                audioEle.load();
            },

            /**
             * 播放/暂停录音
             */
            playAudio() {
                let that = this
                let audioEle = document.getElementById('audioPlayer');
                if (this.isPlay) {
                    this.isPlay = false;
                    audioEle.pause();
                } else {
                    this.isPlay = true;
                    this.playTime = 0;
                    audioEle.play();

                    //记录播放时长
                    if (that.playTimer) {
                        clearInterval(that.playTimer);
                    }
                    that.playTimer = setInterval(function () {
                        if (that.playTime === that.totalDuration || that.isPlay === false) {
                            clearInterval(that.playTimer);
                            that.isPlay = false
                        } else {
                            that.playTime++;
                        }
                    }, 1000);

                }
            },

            /**
             * 删除音频
             */
            deleteAudio() {
                this.showAudio('')

                this.playTime = 0;
                this.totalDuration = 0;

                //清空数据
                this.audioChunks = [];
                this.$store.commit('SET_AUDIO_FILE', null)
                this.$store.commit('SET_AUDIO_URL', null)
            },

            /**
             * 格式化秒数
             */
            formatSeconds(second) {
                if (second < 10) {
                    return '0' + second;
                } else {
                    return second;
                }
            },

            /**
             * 上一题
             */
            prevClick() {
                if (this.isRecording) {
                    this.$toast(this.$t('stopRecord'))
                    return;
                }
                this.$store.commit('SET_AUDIO_DURATION', this.totalDuration)
                this.$router.push('/photo')
            },

            /**
             * 下一题
             */
            nextClick() {
                if (this.isRecording) {
                    this.$toast(this.$t('stopRecord'))
                    return;
                }
                this.$store.commit('SET_AUDIO_DURATION', this.totalDuration)
                this.$router.push('/advice')
            },

            /**
             * 倒计时
             */
            countdown() {
                let that = this;
                this.countNumber = 2;
                if (this.btnTimer) {
                    clearInterval(this.btnTimer);
                }
                this.btnTimer = setInterval(function () {
                    if (that.countNumber === 0) {
                        clearInterval(this.btnTimer);
                    } else {
                        that.countNumber--;
                    }
                }, 1000);
            }
        }
    };
</script>
<style scoped lang="less">

    .audio-back {
        height: 100%;

        .audio-box {
            width: calc(0.86 * var(--body-width));
            height: 54px;
            line-height: 54px;
            margin: calc(0.081 * var(--body-height)) auto calc(0.071 * var(--body-height));
            text-align: center;
            box-sizing: border-box;
            padding: 0 8px;
            font-size: 24px;
            background-color: #fff;
            border: 1px solid #ddd;
            border-radius: 10px;

            .audio-plugin {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .text {
                    font-size: 12px;
                }
            }


        }

        .out-ring {
            width: 100px;
            height: 100px;
            background: rgba(63, 117, 168, 0.2);
            border-radius: 50%;
            text-align: center;
            padding: 11px;
            box-sizing: border-box;
            margin: 0 auto;

            .in-ring {
                width: 78px;
                height: 78px;
                line-height: 78px;
                background: #FFFFFF;
                box-shadow: 0 3px 6px 0 rgba(133, 166, 199, 0.6);
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;

                .red-square {
                    width: 20px;
                    height: 20px;
                    background: #F22525;
                    border-radius: 5px;
                }
            }
        }

        .tip {
            text-align: center;
            font-size: 14px;
            color: #999999;
            line-height: 30px;
        }

    }

</style>