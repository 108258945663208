import axios from './http'

const login = {

    /**
     * 注册接口
     * @param form
     */
    register(form) {
        return axios.post(`/urnature/HAPLATUSERSERVICE/haplatuser/v1/account/registerPhone`, form)
    },

    /**
     * 获取验证码
     * @param phoneNumber
     */
    getCodeByPhone(phoneNumber) {
        return axios.get(`/urnature/HAPLATUSERSERVICE/haplatuser/v1/account/getPhoneCode?phoneNumber=${phoneNumber}&plat=urNature`)
    },


    /**
     * 账号登录
     */
    accountLogon(form) {
        return axios.post(`/urnature/HAPLATUSERSERVICE/haplatuser/v1/account/login?rememberMe=${false}`, form)
    },

    /**
     * 手机号登录
     */
    phoneLogon(form) {
        let code = form.phoneCode
        let phone = form.phoneNumber
        let codeToken = form.codeToken
        return axios.post(`/urnature/HAPLATUSERSERVICE/haplatuser/v1/account/phone?phoneCode=${code}&phoneNumber=${phone}`, {},
            {
                headers: {'code-token': codeToken}
            })
    },

    /**
     * 登出
     */
    logout() {
        return axios.get(`/urnature/HAPLATUSERSERVICE/haplatuser/v1/account/signOut`)
    },

    /**
     * 获取用户信息
     */
    getUserInfo() {
        return axios.get('/urnature/HAPLATUSERSERVICE/haplatuser/v1/user/gate/user')
    },

    /**
     * 手机号是否存在
     */
    isPhoneExist(phone) {
        return axios.get(`/urnature/HAPLATUSERSERVICE/haplatuser/v1/account/existPhoneNumber?phoneNumber=${phone}`)
    }

}
export default login
