<template>
    <div class="layout-back">
        <div style="height:1px;"></div>
        <el-progress :percentage="this.answerPercent"
                     :stroke-width="4"
                     :show-text="false"></el-progress>
        <div style="height:calc(100% - 5px);">
            <router-view/>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        name: "index1",
        data() {
            return {}
        },
        computed: {
            ...mapGetters([
                'answerPercent'
            ])
        },
        methods: {}
    }
</script>

<style scoped lang="less">
    .layout-back {
        width: 100%;
        height: 100%;
        background-image: url("../../assets/img/ecoBack.png");
        background-size: cover;

    }


</style>