import api from '@/api'
import {
    getToken, setToken, removeToken,
    getUserId, setUserId, removeUserId
} from '@/user_info/auth'

const SET_USERTOKEN = 'SET_USERTOKEN'
const SET_ID = 'SET_ID'
const SET_USERNAME = 'SET_USERNAME'
const SET_NICKNAME = 'SET_NICKNAME'

const user = {
    state: {
        userToken: getToken(),
        id: getUserId(),
        userName: '',
        nickName: ''
    },
    mutations: {
        [SET_USERTOKEN](state, userToken) {
            state.userToken = userToken
        },
        [SET_ID](state, id) {
            state.id = id
        },
        [SET_USERNAME](state, userName) {
            state.userName = userName
        },
        [SET_NICKNAME](state, nickName) {
            state.nickName = nickName
        }

    },
    actions: {

        /**
         * 用户注册并登录
         * @param commit
         * @param rootState
         * @param userInfo
         */
        registLog({commit, rootState}, userInfo) {
            return new Promise((resolve, reject) => {
                api.loginApi.register(userInfo).then(resp => {
                    let data = resp.data.result
                    setToken(data.userToken)
                    commit(SET_USERTOKEN, data.userToken)
                    setUserId('')
                    commit(SET_ID, '')
                    commit(SET_USERNAME, data.userName)
                    commit(SET_NICKNAME, data.trueName)
                    return resolve(data)
                }).catch(err => {
                    return reject(err)
                })
            })
        },

        /**
         * 账号登录
         * @param commit 账号密码
         * @param userInfo 用户数据
         */
        login({commit, rootState}, userInfo) {
            return new Promise((resolve, reject) => {
                api.loginApi.accountLogon(userInfo).then(resp => {
                    let token = resp.data.result
                    setToken(token)
                    setUserId('')
                    commit(SET_USERTOKEN, token)
                    commit(SET_ID, '')
                    commit(SET_USERNAME, '')
                    commit(SET_NICKNAME, '')
                    return resolve(resp.data)
                }).catch(err => {
                    return reject(err)
                })
            })
        },

        /**
         * 手机号登录
         * @param commit 账号密码
         * @param userInfo 用户数据
         */
        phoneLog({commit, rootState}, userInfo) {
            return new Promise((resolve, reject) => {
                api.loginApi.phoneLogon(userInfo).then(resp => {
                    let data = resp.data.result
                    setToken(data.userToken)
                    setUserId('')
                    commit(SET_USERTOKEN, data.userToken)
                    commit(SET_ID, '')
                    commit(SET_USERNAME, data.userName)
                    commit(SET_NICKNAME, data.trueName)
                    return resolve(resp.data)
                }).catch(err => {
                    return reject(err)
                })
            })
        },

        /**
         * 拉取用户信息
         * @param commit
         * @returns {Promise<unknown>}
         */
        pullUserInfo({commit, rootState}) {
            return new Promise((resolve, reject) => {
                api.loginApi.getUserInfo().then(resp => {
                    let data = resp.data.result
                    if (data !== null) {
                        commit(SET_USERNAME, data[0])
                        setUserId(data[1])
                        commit(SET_ID, data[1])
                        // commit(SET_NICKNAME, data.realName)
                    }
                    return resolve(data)
                }).catch(err => {
                    return reject(err)
                })
            })
        },

        /**
         * 用户退出登录
         * @param commit
         */
        logout({commit}) {
            return new Promise((resolve, reject) => {
                api.loginApi.logout().then(resp => {
                    removeToken()
                    removeUserId()
                    commit(SET_USERTOKEN, '')
                    commit(SET_ID, '')
                    return resolve()
                }).catch(err => {
                    return reject(err)
                })
            })
        }
    },
    getters: {
        userToken: state => state.userToken,
        userId: state => state.id,
        userName: state => state.userName,
        nickName: state => state.nickName
    }
}

export default user
