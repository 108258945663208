import Vue from 'vue'
import VueRouter from 'vue-router'


import Login from '../views/login'
import Empty from '../views/login/empty'
import Start from '../views/start'
import Rule from '../views/start/rule'
import Notice from '../views/userNotice'

import Layout from '../views/layout'
import Location1 from '../views/location/step1'
import Location2 from '../views/location/step2'
import Location3 from '../views/location/step3'
import Garden from '../views/location/step3_garden'
import Enter1 from '../views/location/garden_enter1'
import Enter2 from '../views/location/garden_enter2'
import Enter3 from '../views/location/garden_enter3'
import Enter4 from '../views/location/garden_enter4'
import Enter5 from '../views/location/garden_enter5'
import Leave1 from '../views/location/garden_leave1'

import Location4 from '../views/location/step4'
import Nature from '../views/nature'
import Section from '../views/nature/section'
import Slider from '../views/mental/slider'
import SliderSubject from '../views/mental/slider_subject'
import Health from '../views/mental/health'
import HealthSection from '../views/mental/health_section'
import MentalRadio from '../views/mental/radio'
import MentalRadioSection from '../views/mental/radio_section'
import Photo from '../views/photo'
import Audio from '../views/audio/index'
import Advice from '../views/advice'
import End from '../views/endPage'

import Statistics from '../views/statistics'

Vue.use(VueRouter)

//解决重复点击路由报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

const routes = [
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
            title: 'ema',
            keepAlive: false
        }
    },
    {
        path: '/empty',
        name: 'empty',
        component: Empty,
        meta: {
            title: 'ema',
            keepAlive: false
        }
    },
    {
        path: '/start',
        name: 'start',
        component: Start,
        meta: {
            title: 'QuesFill',
            keepAlive: false
        }
    },
    {
        path: '/rule',
        name: 'rule',
        component: Rule,
        meta: {
            title: 'QuesFill',
            keepAlive: false
        }
    },
    {
        path: '/notice',
        name: 'notice',
        component: Notice,
        meta: {
            title: 'QuesFill',
            keepAlive: false
        }
    },
    {
        path: '/layout',
        name: 'layout',
        component: Layout,
        children:[
            {
                path: '/location1',
                name: 'location1',
                component: Location1,
                meta: {
                    title: 'QuesFill',
                    keepAlive: false
                }
            },
            {
                path: '/location2',
                name: 'location2',
                component: Location2,
                meta: {
                    title: 'QuesFill',
                    keepAlive: false
                }
            },
            {
                path: '/location3',
                name: 'location3',
                component: Location3,
                meta: {
                    title: 'QuesFill',
                    keepAlive: false
                }
            },
            {
                path: '/garden',
                name: 'garden',
                component: Garden,
                meta: {
                    title: 'QuesFill',
                    keepAlive: false
                }
            },
            {
                path: '/enter1',
                name: 'enter1',
                component: Enter1,
                meta: {
                    title: 'QuesFill',
                    keepAlive: false
                }
            },
            {
                path: '/enter2',
                name: 'enter2',
                component: Enter2,
                meta: {
                    title: 'QuesFill',
                    keepAlive: false
                }
            },
            {
                path: '/enter3',
                name: 'enter3',
                component: Enter3,
                meta: {
                    title: 'QuesFill',
                    keepAlive: false
                }
            },
            {
                path: '/enter4',
                name: 'enter4',
                component: Enter4,
                meta: {
                    title: 'QuesFill',
                    keepAlive: false
                }
            },
            {
                path: '/enter5',
                name: 'enter5',
                component: Enter5,
                meta: {
                    title: 'QuesFill',
                    keepAlive: false
                }
            },
            {
                path: '/leave1',
                name: 'leave1',
                component: Leave1,
                meta: {
                    title: 'QuesFill',
                    keepAlive: false
                }
            },
            {
                path: '/location4',
                name: 'location4',
                component: Location4,
                meta: {
                    title: 'QuesFill',
                    keepAlive: false
                }
            },
            {
                path: '/nature',
                redirect:'/nature/a',
                component: Nature,
                meta: {
                    title: 'QuesFill',
                    keepAlive: false
                },
                children:[
                    {
                        path: 'a',
                        name: 'b',
                        component: Section
                    }
                ]
            },
            {
                path: '/mental',
                redirect:'/mental/a',
                component: Slider,
                meta: {
                    title: 'QuesFill',
                    keepAlive: false
                },
                children:[
                    {
                        path: 'a',
                        name: 'c',
                        component: SliderSubject
                    }
                ]
            },
            {
                path: '/health',
                redirect:'/health/a',
                component: Health,
                meta: {
                    title: 'QuesFill',
                    keepAlive: false
                },
                children:[
                    {
                        path: 'a',
                        name: 'e',
                        component: HealthSection
                    }
                ]
            },
            {
                path: '/mental_radio',
                redirect:'/mental_radio/a',
                component: MentalRadio,
                meta: {
                    title: 'QuesFill',
                    keepAlive: false
                },
                children:[
                    {
                        path: 'a',
                        name: 'd',
                        component: MentalRadioSection
                    }
                ]
            },
            {
                path: '/photo',
                name: 'photo',
                component: Photo,
                meta: {
                    title: 'QuesFill',
                    keepAlive: false
                }
            },
            {
                path: '/audio',
                name: 'audio',
                component: Audio,
                meta: {
                    title: 'QuesFill',
                    keepAlive: false
                }
            },
            {
                path: '/advice',
                name: 'advice',
                component: Advice,
                meta: {
                    title: 'QuesFill',
                    keepAlive: false
                }
            },
            {
                path: '/end',
                name: 'end',
                component: End,
                meta: {
                    title: 'QuesFill',
                    keepAlive: false
                }
            }
        ]
    },
    {
        path: '/statistics',
        name: 'statistics',
        component: Statistics,
        meta: {
            title: 'statisticResult',
            keepAlive: false
        }
    },
    {
        path: '*', // 通用路由
        redirect: '/start'
    }
]

const router = new VueRouter({
    // base:'quzhou',
    mode: 'history',
    routes
})

export default router
