import Cookies from 'js-cookie'

const TokenKey = 'userToken'
const IdKey = 'userId'
const domain = '.urnature.net'
// const domain = '10.0.0.168'
// const domain = ''

function setToken(token) {
    return Cookies.set(TokenKey, token,{domain: domain})
}

function getToken() {
    return Cookies.get(TokenKey)
}

function removeToken() {
    return Cookies.remove(TokenKey,{domain: domain})
}

function setUserId(userId) {
    return Cookies.set(IdKey, userId,{domain: domain})
}

function getUserId() {
    return Cookies.get(IdKey)
}

function removeUserId() {
    return Cookies.remove(IdKey,{domain: domain})
}


export {
    getToken,
    setToken,
    removeToken,
    getUserId,
    setUserId,
    removeUserId
}
