<template>
    <div class="location-back">
        <!--环图片-->
        <div class="img-box">
            <div class="prop-up"></div>
            <img src="@/assets/img/circle.png" class="circle" alt="">
        </div>
        <!--题目-->
        <div class="subject">
            <div>{{$t('enterTitle2')}}</div>
            <div>{{$t('enterTitle22')}}</div>
        </div>
        <!--选项-->
        <div style="width:130px;margin:calc(0.073 * var(--body-height)) auto 0;">
            <el-radio-group v-model="number">
                <el-radio :label="$t('times1')"></el-radio>
                <el-radio :label="$t('times2')"></el-radio>
                <el-radio :label="$t('times3')"></el-radio>
                <el-radio :label="$t('times4')"></el-radio>
            </el-radio-group>
        </div>
        <!--题目跳转-->
        <div class="subject-jump">
            <span @click="prevClick" class="prev-btn">{{$t('preQues')}}</span>
            <el-button @click="nextClick"
                       :disabled="countNumber!=0"
                       style="width:160px;"
                       type="primary">{{$t('nextQues')}} <i class="icon-right"></i> ({{countNumber}}s)
            </el-button>
        </div>
    </div>

</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        name: "garden_enter2",
        data() {
            return {
                //次数
                number: '',
                timer: null,
                countNumber: 0
            }
        },
        computed: {
            ...mapGetters([
                'enter2Answer'
            ]),
        },
        watch: {
            '$route': {
                immediate: true, // 组件创建时立即触发
                handler(newVal, oldVal) {
                    this.$store.commit('SET_ANSWER_PERCENT', 100 / 21)
                }
            }
        },
        mounted() {
            this.countdown()
            this.number = this.enter2Answer
        },
        methods: {

            /**
             * 上一题
             */
            prevClick() {
                this.$router.push('/enter1')
            },

            /**
             * 下一题
             */
            nextClick() {
                if (this.number == '') {
                    this.$toast(this.$t('selectOne'));
                    return;
                }
                //保存答案
                this.$store.commit('SET_ENTER2_ANSWER', this.number)
                this.$router.push('/enter3')
            },

            /**
             * 验证码倒计时
             */
            countdown() {
                let that = this;
                this.countNumber = 2;
                if (this.timer) {
                    clearInterval(this.timer);
                }
                this.timer = setInterval(function () {
                    if (that.countNumber === 0) {
                        clearInterval(this.timer);
                    } else {
                        that.countNumber--;
                    }
                }, 1000);
            }
        }
    }
</script>

<style scoped lang="less">
    .location-back {
        height: 100%;
    }

</style>