const SET_ANSWER_PERCENT = 'SET_ANSWER_PERCENT'
const SET_RECORD_ID = 'SET_RECORD_ID'
const SET_MARKS = 'SET_MARKS'
const SET_BEANS = 'SET_BEANS'

const SET_LAT = 'SET_LAT'
const SET_LON = 'SET_LON'

const SET_HEALTH_FLAG = 'SET_HEALTH_FLAG'
const SET_AGGRESSION_FLAG = 'SET_AGGRESSION_FLAG'

const SET_LOCATION1_ANSWER = 'SET_LOCATION1_ANSWER'
const SET_LOCATION2_ANSWER = 'SET_LOCATION2_ANSWER'
const SET_LOCATION3_ANSWER = 'SET_LOCATION3_ANSWER'
const SET_GARDEN_ANSWER = 'SET_GARDEN_ANSWER'
const SET_ENTER1_ANSWER = 'SET_ENTER1_ANSWER'
const SET_ENTER2_ANSWER = 'SET_ENTER2_ANSWER'
const SET_ENTER3_ANSWER = 'SET_ENTER3_ANSWER'
const SET_ENTER4_ANSWER = 'SET_ENTER4_ANSWER'
const SET_ENTER5_ANSWER = 'SET_ENTER5_ANSWER'
const CLEAR_ENTER = 'CLEAR_ENTER'
const SET_LEAVE1_ANSWER = 'SET_LEAVE1_ANSWER'
const SET_LOCATION4_ANSWER = 'SET_LOCATION4_ANSWER'

const SET_NATURE_ANSWER_ARR = 'SET_NATURE_ANSWER_ARR'
const SET_SLIDER_ANSWER = 'SET_SLIDER_ANSWER'
const SET_MENTAL_RADIO_ANSWER = 'SET_MENTAL_RADIO_ANSWER'

const SET_CAMERA_SHOW = 'SET_CAMERA_SHOW'
const SET_PHOTO_FILE = 'SET_PHOTO_FILE'
const SET_AUDIO_FILE = 'SET_AUDIO_FILE'
const SET_AUDIO_URL = 'SET_AUDIO_URL'
const SET_AUDIO_DURATION = 'SET_AUDIO_DURATION'

const question = {
    state: {
        //答题进度
        answerPercent: 0,
        //问卷记录id
        recordId: '',
        //答卷奖励自然分
        marks: 0,
        //答卷奖励自然豆
        beans: 0,

        //用户定位经纬度
        lat: 0,
        lon: 0,

        //健康问题标志：0-未回答，1-已回答
        healthFlag:0,
        //攻击性问题标志：0-未回答，1-已回答
        aggressionFlag:0,

        //位置1答案
        location1Answer: '',
        location2Answer: ['', ''],
        location3Answer: ['', ''],
        gardenAnswer:'',
        enter1Answer:'',
        enter2Answer:'',
        enter3Answer:[],
        enter4Answer:[],
        enter5Answer:'',
        leave1Answer:'',
        location4Answer: '',

        //自然题答案
        natureAnswerArr: [
            [1, ''],
            [2, ''],
            [3, '']
        ],

        sliderAnswer: [
            [1, 0],
            [2, 0],
            [3, 0],
            [4, 0],
            [5, 0],
            [6, 0]
        ],

        mentalRadioAnswer: [
            [1, ''],
            [2, ''],
            [3, ''],
            [4, ''],
            [5, ''],
            [6, ''],
            [7, ''],
        ],

        cameraShow: true,
        photoFile: null,
        audioFile: null,
        audioUrl: null,
        //录音时长
        audioDuration: 0
    },

    mutations: {

        /**
         * 设置答题进度
         * @param state
         * @param payload
         */
        [SET_ANSWER_PERCENT](state, payload) {
            state.answerPercent = payload
        },

        /**
         * 设置问卷记录id
         * @param state
         * @param payload
         */
        [SET_RECORD_ID](state, payload) {
            state.recordId = payload
        },

        /**
         * 设置自然分
         * @param state
         * @param payload
         */
        [SET_MARKS](state, payload) {
            state.marks = payload
        },

        /**
         * 设置自然豆
         * @param state
         * @param payload
         */
        [SET_BEANS](state, payload) {
            state.beans = payload
        },

        /**
         * 设置纬度
         * @param state
         * @param payload
         */
        [SET_LAT](state, payload) {
            state.lat = payload
        },

        /**
         * 设置经度
         * @param state
         * @param payload
         */
        [SET_LON](state, payload) {
            state.lon = payload
        },

        /**
         * 设置健康问题标志
         * @param state
         * @param payload
         */
        [SET_HEALTH_FLAG](state, payload) {
            state.healthFlag = payload
        },

        /**
         * 设置攻击性问题标志
         * @param state
         * @param payload
         */
        [SET_AGGRESSION_FLAG](state, payload) {
            state.aggressionFlag = payload
        },

        /**
         * 设置位置1答案
         * @param state
         * @param payload
         */
        [SET_LOCATION1_ANSWER](state, payload) {
            state.location1Answer = payload
        },

        [SET_LOCATION2_ANSWER](state, payload) {
            state.location2Answer = payload
        },

        [SET_LOCATION3_ANSWER](state, payload) {
            state.location3Answer = payload
        },

        [SET_GARDEN_ANSWER](state, payload) {
            state.gardenAnswer = payload
        },
        [SET_ENTER1_ANSWER](state, payload) {
            state.enter1Answer = payload
        },
        [SET_ENTER2_ANSWER](state, payload) {
            state.enter2Answer = payload
        },
        [SET_ENTER3_ANSWER](state, payload) {
            state.enter3Answer = payload
        },
        [SET_ENTER4_ANSWER](state, payload) {
            state.enter4Answer = payload
        },
        [SET_ENTER5_ANSWER](state, payload) {
            state.enter5Answer = payload
        },
        [CLEAR_ENTER](state) {
            state.enter1Answer = ''
            state.enter2Answer = ''
            state.enter3Answer = []
            state.enter4Answer = []
            state.enter5Answer = ''
        },
        [SET_LEAVE1_ANSWER](state, payload) {
            state.leave1Answer = payload
        },

        [SET_LOCATION4_ANSWER](state, payload) {
            state.location4Answer = payload
        },

        [SET_NATURE_ANSWER_ARR](state, payload) {
            state.natureAnswerArr = payload
        },

        [SET_SLIDER_ANSWER](state, payload) {
            state.sliderAnswer = payload
        },

        [SET_MENTAL_RADIO_ANSWER](state, payload) {
            state.mentalRadioAnswer = payload
        },

        //设置拍照页面照相机图片显示与否
        [SET_CAMERA_SHOW](state, payload) {
            state.cameraShow = payload
        },

        [SET_PHOTO_FILE](state, payload) {
            state.photoFile = payload
        },

        [SET_AUDIO_FILE](state, payload) {
            state.audioFile = payload
        },

        [SET_AUDIO_URL](state, payload) {
            state.audioUrl = payload
        },

        [SET_AUDIO_DURATION](state, payload) {
            state.audioDuration = payload
        }

    },

    getters: {
        //答题进度
        answerPercent: state => state.answerPercent,
        //问卷记录id
        answerRecordId: state => state.recordId,
        marks: state => state.marks,
        beans: state => state.beans,

        //经纬度
        lat: state => state.lat,
        lon: state => state.lon,

        //答题限制标志
        healthFlag: state => state.healthFlag,
        aggressionFlag: state => state.aggressionFlag,

        //位置1答案
        location1Answer: state => state.location1Answer,
        location2Answer: state => state.location2Answer,
        location3Answer: state => state.location3Answer,
        gardenAnswer: state => state.gardenAnswer,
        enter1Answer: state => state.enter1Answer,
        enter2Answer: state => state.enter2Answer,
        enter3Answer: state => state.enter3Answer,
        enter4Answer: state => state.enter4Answer,
        enter5Answer: state => state.enter5Answer,
        leave1Answer: state => state.leave1Answer,
        location4Answer: state => state.location4Answer,

        //自然题答案数组
        natureAnswerArr: state => state.natureAnswerArr,
        //滑块题答案数组
        sliderAnswer: state => state.sliderAnswer,
        //心理单选题答案数组
        mentalRadioAnswer: state => state.mentalRadioAnswer,

        //相机显示与否
        cameraShow: state => state.cameraShow,
        photoFile: state => state.photoFile,
        audioFile: state => state.audioFile,
        audioUrl: state => state.audioUrl,
        audioDuration: state => state.audioDuration

    }
}

export default question