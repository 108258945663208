<template>
    <div class="photo-back">
        <!--文档图片-->
        <div class="img-box">
            <div class="prop-up"></div>
            <img src="@/assets/img/circle.png" class="circle" alt="">
        </div>
        <!--题目-->
        <div class="subject">
            <div>{{$t('takePhoto')}}</div>
        </div>
        <!--input标签-->
        <input ref="photoRef"
               type="file"
               accept="image/*"
               capture="environment"
               @change="handleImageCapture"
               style="display:none">
        <!-- 照片框-->
        <div class="photo-box">
            <!-- 拍照 -->
            <div v-if="cameraShow" @click="cameraClick" class="camera-box">
                <img src="@/assets/img/camera.png" alt="" class="camera-icon">
                <div class="text">{{$t('clickToTake')}}</div>
            </div>
            <!-- 照片 -->
            <div v-else class="picture-box">
                <span @click="deletePic">
                    <i class="el-icon-close"></i>
                </span>
                <img id="photoImg" src="" alt="">
            </div>
        </div>
        <!--题目跳转-->
        <div class="subject-jump">
            <span @click="prevClick" class="prev-btn"><i class="icon-left"></i> {{$t('preQues')}}</span>
            <el-button @click="nextClick"
                       :disabled="countNumber!=0"
                       style="width:160px;"
                       type="primary">{{$t('nextQues')}} <i class="icon-right"></i> ({{countNumber}}s)
            </el-button>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        data() {
            return {
                countNumber: 0
            }
        },
        watch: {
            '$route': {
                immediate: true, // 组件创建时立即触发
                handler(newVal, oldVal) {
                    this.$store.commit('SET_ANSWER_PERCENT', 19 * 100 / 21)
                }
            }
        },
        computed: {
            ...mapGetters([
                'userId',
                'cameraShow',
                'photoFile',
                'healthFlag',
                'aggressionFlag',
            ]),
        },
        mounted() {
            this.countdown();
            if (this.photoFile) {
                this.showPhoto(this.photoFile)
            }
        },
        methods: {

            /**
             * 相机点击事件
             */
            cameraClick() {
                this.$refs.photoRef.dispatchEvent(new MouseEvent('click'));
            },

            /**
             * 上传照片事件
             */
            handleImageCapture(event) {
                if (event.target.files.length === 0) {
                    return;
                }
                this.$store.commit('SET_CAMERA_SHOW', false)
                let file = event.target.files[0];

                //在页面展示图片
                this.showPhoto(file)

                this.$store.commit('SET_PHOTO_FILE', file)
            },

            /**
             * 在页面展示照片
             */
            showPhoto(file) {
                let reader = new FileReader();
                reader.onload = function (e) {
                    let img = document.getElementById('photoImg');
                    img.src = e.target.result;
                };
                reader.readAsDataURL(file);
            },

            /**
             * 删除照片
             */
            deletePic() {
                this.$store.commit('SET_CAMERA_SHOW', true)
                this.$store.commit('SET_PHOTO_FILE', null)
            },

            /**
             * 上一题
             */
            prevClick() {
                if (this.aggressionFlag == 0) {
                    this.$router.push({
                        path: '/mental_radio/a',
                        query: {
                            id: 7
                        }
                    })
                } else {
                    if (this.healthFlag == 0) {
                        this.$router.push({
                            path: '/health/a',
                            query: {
                                id: 2
                            }
                        })
                    } else {
                        this.$router.push({
                            path: '/mental/a',
                            query: {
                                id: 6
                            }
                        })
                    }
                }
            },

            /**
             * 下一题
             */
            nextClick() {
                this.$router.push('/audio')
            },

            /**
             * 倒计时
             */
            countdown() {
                let that = this;
                this.countNumber = 2;
                if (this.timer) {
                    clearInterval(this.timer);
                }
                this.timer = setInterval(function () {
                    if (that.countNumber === 0) {
                        clearInterval(this.timer);
                    } else {
                        that.countNumber--;
                    }
                }, 1000);
            }
        }
    };
</script>
<style scoped lang="less">
    .photo-back {
        height: 100%;
    }

    .photo-box {
        width: calc(0.84 * var(--body-width));
        height: calc(0.296 * var(--body-height));
        background: rgba(255, 255, 255, 0.7);
        border-radius: 10px;
        margin: calc(0.049 * var(--body-height)) auto 0;

        display: flex;
        align-items: center;
        justify-content: center;

        .camera-box {
            text-align: center;

            .camera-icon {
                height: calc(0.037 * var(--body-height));
            }

            .text {
                font-size: 14px;
                color: #999999;
                line-height: 30px;
            }
        }

        .picture-box {
            position: relative;
            width: 100%;
            height: 100%;

            span {
                position: absolute;
                top: 12px;
                right: 12px;

                width: 23px;
                height: 23px;
                border-radius: 50%;
                text-align: center;
                font-size: 13px;
                line-height: 23px;
                background: #DDDDDD;
            }

            img {
                width: 100%;
                height: 100%;
            }

        }


    }

</style>