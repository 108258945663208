import axios from './http'
import qs from 'qs'
const questionnaire = {

    /**
     * 获取信息
     * @param chooseLang
     */
    getInfo(chooseLang){
        return axios.get(`/urnature/MOMENTAASSESSMENTSERVICE/free/gate/momentaryassessment/main/getMessageByChoose?chooseLang=${chooseLang}`)
    },

    /**
     * 添加用户详细信息
     */
    addUserDetail() {
        return axios.post(`/urnature/URNATURESERVICE/user/gate/urnature/userDetailsInfo/addUserDetailsInfo`)
    },

    /**
     * 获取此次问卷答题奖励
     */
    getReward(userId) {
        return axios.get(`/urnature/MOMENTAASSESSMENTSERVICE/user/gate/momentaryassessment/questionnaire/getAnswerBonus?userId=${userId}`)
    },

    /**
     * 保存问卷答题结果
     */
    saveAnswer(latitude,longitude,result,userId,photoFile,audioFile){
        let formData = new FormData();
        formData.append('questionnaireImage',photoFile)
        formData.append('questionnaireAudio',audioFile)
        return axios.post(`/urnature/MOMENTAASSESSMENTSERVICE/user/gate/momentaryassessment/questionnaire/addQuestionAnswer?latitude=${latitude}&longitude=${longitude}&result=${result}&userId=${userId}`,formData)
    },

    /**
     * 获取用户电话
     */
    getUserPhone(){
        return axios.get(`/urnature/HAPLATUSERSERVICE/haplatuser/v1/user/gate/getNewUserInfo`)
    },

    /**
     * 保存问卷建议
     */
    saveAdvice(phone,suggestion,userName){
        let form = {
            phone:phone,
            questionnaireId:2,
            suggestion:suggestion,
            userName:userName
        }
        return axios.post(`/urnature/MOMENTAASSESSMENTSERVICE/user/gate/momentaryassessment/questionnaire/addQuestionnaireSuggestion`,form)
    },

    /**
     * 获取本周统计结果
     */
    getStatistics(userId){
        return axios.get(`/urnature/MOMENTAASSESSMENTSERVICE/user/gate/momentaryassessment/questionnaire/statistics?userId=${userId}`)
    },

    /**
     * 获取用户详细信息
     */
    getUserDetail(userId) {
        return axios.get(`/urnature/URNATURESERVICE/user/gate/urnature/userDetailsInfo/getByUserId?userId=${userId}`)
    },

    /**
     * 判断今天答题是否不超过10次
     */
    isLessTen(userId){
        return axios.get(`/urnature/MOMENTAASSESSMENTSERVICE/user/gate/momentaryassessment/questionnaire/judgeAnswerNumber?userId=${userId}`)
    }

}
export default questionnaire
