<template>
    <div class="end-back">
        <div class="pic-box">
            <div style="height:calc(0.133 * var(--body-height));"></div>
            <img src="@/assets/img/handshake.png" class="pic" alt="">
        </div>
        <div class="thank">{{$t('thankAssess')}}</div>
        <div class="coin-tip">{{$t('answerAdd')}}
            <span> {{marks}} {{$t('score')}}</span>,
            <span> {{beans}} {{$t('bean')}}</span>
        </div>

        <div @click="checkStatistics" class="check-btn">{{$t('checkStatistics')}}</div>
        <div @click="backToStart" class="check-btn back-btn" style="">{{$t('back')}}
        </div>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        name: "index",
        data() {
            return {}
        },
        computed: {
            ...mapGetters([
                'marks',
                'beans',
                'answerRecordId'
            ]),
        },
        watch: {
            '$route': {
                immediate: true, // 组件创建时立即触发
                handler(newVal, oldVal) {
                    this.$store.commit('SET_ANSWER_PERCENT', 100)
                }
            }
        },
        methods: {

            /**
             * 查看本周统计
             */
            checkStatistics() {
                this.$router.push('/statistics');
            },

            /**
             * 返回开始页
             */
            backToStart() {
                this.$router.push('/start');
            }
        }
    }
</script>

<style scoped lang="less">
    .end-back {
        height: 100%;
        text-align: center;
        position: relative;
    }

    .pic-box {
        height: calc(0.334 * var(--body-height));

        .pic {
            /*width: 152px;*/
            height: calc(0.125 * var(--body-height));
        }
    }

    .thank {
        font-size: 25px;
        color: #333333;
    }

    .coin-tip {
        font-size: 14px;
        color: #666666;
        margin: 15px 0 10px 0;

        span {
            color: #E8A911;
        }
    }

    .check-btn {
        position: absolute;
        bottom: calc(0.25 * var(--body-height));
        left: 50%;
        margin-left: -90px;
        width: 180px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        background: #F6B122;
        color: #333333;
        border-radius: 10px;
        cursor: pointer;
    }

    .back-btn {
        bottom: calc(0.169 * var(--body-height));
        background: #3F75A8;
        color:#fff;
    }


</style>