<template>
    <div class="statistics-back">
        <div class="head-box">
            <div style="height:15px;"></div>
            <div class="time-range">{{startDate}}-{{endDate}}</div>
            <div class="tip">*{{$t('summary')}}</div>
        </div>
        <div class="data-card">
            <!--柱状图-->
            <div id="chartId" class="bar-chart"></div>
            <!--地图-->
            <div id="bdMap"></div>
            <!-- 返回按钮-->
            <div @click="backToStart" class="back-btn">{{$t('back')}}</div>
        </div>
    </div>
</template>

<script>
    import api from '@/api'
    import {mapGetters} from "vuex";
    import * as echarts from 'echarts';

    export default {
        name: "index",
        data() {
            return {
                startDate: '',
                endDate: '',
                chartObj: null,
                //本周日期数组
                dataArr: [],
                //本周暴露程度数组
                levelArr: [],
                //本周定位地点数组
                spotArr: [],
                map: null,
            }
        },
        computed: {
            ...mapGetters([
                'userId'
            ]),
        },
        mounted() {
            this.getWeekResult()
        },
        methods: {

            /**
             * 获取本周统计结果
             */
            getWeekResult() {
                api.questionnaire.getStatistics(this.userId).then(res => {
                    let arr = res.data.result;
                    //获取本周开始、结束时间
                    this.startDate = arr[6].statisticalTime.slice(5).replace('-', '/')
                    this.endDate = arr[0].statisticalTime.slice(5).replace('-', '/')
                    //获取本周时间，暴露程度，地点数组
                    this.dateArr = [];
                    this.levelArr = [];
                    let spots = [];
                    for (let i = 6; i >= 0; i--) {
                        let date = arr[i].statisticalTime.slice(8)
                        let level = this.changeLevelToNum(arr[i].naturalExposureLevel);
                        let spot = arr[i].locationInfo
                        this.dateArr.push(date)
                        this.levelArr.push(level)
                        if (spot) {
                            spots.push(...spot)
                        }
                    }
                    this.spotArr = this.uniqueArray2D(spots)
                    //初始化柱状图
                    this.initChart();
                    //初始化地图
                    this.initMap()
                })
            },

            /**
             * 初始化柱状图
             */
            initChart() {
                let that = this
                if (this.chartObj == null) {
                    this.chartObj = echarts.init(document.getElementById('chartId'))
                }
                this.chartObj.setOption({
                    title: {
                        text: that.$t('chartTitle'),
                        textStyle: {
                            fontWeight: 'bold',
                            fontSize: '16px'
                        }
                    },
                    grid: {
                        left: '2%',
                        right: '4%',
                        top: '30%',
                        bottom: '0',
                        containLabel: true
                    },
                    xAxis: [
                        {
                            type: 'category',
                            data: that.dateArr,
                            axisTick: {
                                alignWithLabel: true
                            },
                            // axisLabel: {
                            //     formatter: function (value, index) {
                            //         return value + '日'
                            //     }
                            // }
                        }
                    ],
                    yAxis: [
                        {
                            type: 'value',
                            minInterval: 1,
                            max: 3,
                            axisLine: {
                                show: true
                            },
                            axisLabel: {
                                formatter: function (value, index) {
                                    switch (value) {
                                        case 1:
                                            // return '低';
                                            return that.$t('low');
                                        case 2:
                                            // return '中';
                                            return that.$t('medium');
                                        case 3:
                                            // return '高';
                                            return that.$t('high');
                                        default:
                                            return '';
                                    }
                                }
                            }
                        }
                    ],
                    series: [
                        {
                            type: 'bar',
                            barWidth: '10',
                            data: that.levelArr,
                            itemStyle: {
                                borderRadius: [5, 5, 0, 0],
                                color: {
                                    type: 'linear',
                                    x: 0,
                                    y: 0,
                                    x2: 0,
                                    y2: 1,
                                    colorStops: [{
                                        offset: 0, color: 'rgba(63, 117, 168, 1)' // 0% 处的颜色
                                    }, {
                                        offset: 1, color: 'rgba(219, 233, 246, 1)' // 100% 处的颜色
                                    }],
                                    global: false // 缺省为 false
                                }
                            }

                        }
                    ]
                })
            },

            /**
             * 初始化地图
             */
            initMap() {
                let that = this
                let pointsView = []
                this.map = new BMapGL.Map("bdMap", {enableRotate: false, enableTilt: false});
                //开启鼠标滚轮缩放
                this.map.enableScrollWheelZoom(true);
                if (this.spotArr.length < 1) {
                    let point = new BMapGL.Point(116.331398, 39.897445);
                    this.map.centerAndZoom(point, 18);
                    return;
                }

                for (let i = 0; i < this.spotArr.length; i++) {
                    let pointArr = this.spotArr[i]
                    let point = new BMapGL.Point(pointArr[0], pointArr[1]);
                    let mk = new BMapGL.Marker(point);
                    this.map.addOverlay(mk);
                    pointsView.push(point)
                }
                this.map.setViewport(pointsView);
            },

            /**
             * 数组去重
             */
            uniqueArray2D(arr) {
                let uniqueMap = {};
                let result = [];
                arr.forEach(subArr => {
                    const key = subArr.join('-'); // 将子数组转换为字符串
                    if (!uniqueMap[key]) {
                        uniqueMap[key] = true;
                        result.push(subArr);
                    }
                });
                return result;
            },

            /**
             * 将程度字符转化为数值
             */
            changeLevelToNum(level) {
                switch (level) {
                    case 'HIGH':
                        return 3;
                    case 'MEDIUM':
                        return 2;
                    case 'LOW':
                        return 1;
                    default:
                        return 0;
                }
            },

            /**
             * 返回开始页
             */
            backToStart() {
                this.$router.push('/start');
            }

        }
    }
</script>

<style scoped lang="less">

    .statistics-back {
        width: 100%;
        height: 100%;
        background-image: url("../../assets/img/ecoBack.png");
        background-size: cover;
    }

    .head-box {
        height: calc(0.18 * var(--body-height));

        .time-range {
            font-size: 40px;
            line-height: 50px;
            font-weight: bold;
            color: #333333;
            margin: calc(0.034 * var(--body-height)) 0 calc(0.012 * var(--body-height)) calc(0.08 * var(--body-width));
        }

        @media (max-height: 639px) {
            .time-range {
                font-size: 28px;
                line-height: 38px;
                margin: calc(0.03 * var(--body-height)) 0 0 calc(0.08 * var(--body-width));
            }
        }

        .tip {
            font-size: 11px;
            color: #3F75A8;
            margin-left: calc(0.08 * var(--body-width));
        }

    }

    .data-card {
        width: 100%;
        /*height: calc(100% - 140px);*/
        height: calc(0.82 * var(--body-height));
        background: #FFFFFF;
        box-shadow: 0 0 15px 0 rgba(53, 53, 53, 0.17);
        border-radius: 25px 25px 0 0;
        box-sizing: border-box;
        padding: calc(0.0246 * var(--body-height)) calc(0.08 * var(--body-width)) 0;

        .bar-chart {
            width: 100%;
            /*height: calc(0.142 * var(--body-height));*/
            height: calc(0.18 * var(--body-height));
            margin-bottom: calc(0.032 * var(--body-height))
        }

        #bdMap {
            width: 100%;
            height: calc(0.497 * var(--body-height));
            /*height: calc(0.53 * var(--body-height));*/
            /*border-radius: 10px;*/
            border: 1px solid #3B68C3;
            margin-bottom: 5px;
        }

        .back-btn {
            width: 100%;
            height: 35px;
            line-height: 35px;
            text-align: center;
            background: #3F75A8;
            color: #fff;
            border-radius: 10px;
            cursor: pointer;
        }
    }


</style>
