import axios from './http'

const result = {

    /**
     * 计算结果
     */
    evaluateResult(answerRecordId) {
        return axios.post(`/urnature/PSYQUESTIONNAIRESERVICE/user/gate/urnature/questionResult/evaluateResults?answerRecordId=${answerRecordId}`)
    },

    /**
     * 获取结果
     * @param answerRecordId
     */
    getResult(answerRecordId){
        return axios.get(`/urnature/PSYQUESTIONNAIRESERVICE/user/gate/urnature/questionResult/getByAnswerRecordId?answerRecordId=${answerRecordId}`)
    }

}
export default result
