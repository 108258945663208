<template>
    <div class="slider-back">
        <!--环图片-->
        <div class="img-box">
            <div class="prop-up"></div>
            <img src="@/assets/img/circle.png" class="circle" alt="">
        </div>
        <router-view></router-view>
    </div>
</template>

<script>
    export default {
        name: "radio",
        data() {
            return {}
        },
        beforeRouteLeave(to, from, next) {
            next()
        },
        methods:{
        }
    }
</script>

<style scoped lang="less">
    .slider-back {
        height: 100%;
    }

</style>