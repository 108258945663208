<template>
    <div class="location-back">
        <!--环图片-->
        <div class="img-box">
            <div class="prop-up"></div>
            <img src="@/assets/img/circle.png" class="circle" alt="">
        </div>
        <!--题目-->
        <div class="subject">
            <div>{{$t('locateQue3')}}</div>
            <div>{{$t('locateQue3row2')}}</div>
        </div>
        <!--选项-->
        <div style="width:300px;margin:calc(0.025 * var(--body-height)) auto 0;">
            <el-radio-group v-model="outdoorPlace">
                <el-radio :label="$t('outLocate1')"></el-radio>
                <el-radio :label="$t('outLocate2')"></el-radio>
                <el-radio :label="$t('outLocate3')"></el-radio>
                <el-radio :label="$t('outLocate4')"></el-radio>
                <el-radio :label="$t('outLocate5')"></el-radio>
                <el-radio :label="$t('outLocate6')"></el-radio>
                <el-radio :label="$t('outLocate7')"></el-radio>
                <el-radio :label="$t('outLocate8')"></el-radio>
                <div style="display:flex;align-items: baseline">
                    <el-radio :label="$t('outLocate9')"></el-radio>
                    <el-input v-model="otherPosition"
                              maxlength="20"
                              :placeholder="this.$t('outLocate9Specify')"
                              style="width:calc(0.40 * var(--body-width));">
                    </el-input>
                </div>
            </el-radio-group>
        </div>
        <!--题目跳转-->
        <div class="subject-jump">
            <span @click="prevClick" class="prev-btn"><i class="icon-left"></i> {{$t('preQues')}}</span>
            <el-button @click="nextClick"
                       :disabled="countNumber!=0"
                       style="width:160px;"
                       type="primary">{{$t('nextQues')}} <i class="icon-right"></i> ({{countNumber}}s)
            </el-button>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        name: "step3",
        data() {
            return {
                outdoorPlace: '',
                otherPosition: '',
                timer: null,
                countNumber: 0
            }
        },
        computed: {
            ...mapGetters([
                'location3Answer'
            ]),
        },
        watch: {
            '$route': {
                immediate: true, // 组件创建时立即触发
                handler(newVal, oldVal) {
                    this.$store.commit('SET_ANSWER_PERCENT', 100 / 21)
                }
            }
        },
        mounted() {
            this.countdown()
            this.init();
        },
        methods: {

            init() {
                if (this.location3Answer[0] == this.$t('outLocate9')) {
                    this.outdoorPlace = this.location3Answer[0]
                    this.otherPosition = this.location3Answer[1]
                } else {
                    this.outdoorPlace = this.location3Answer[0]
                    this.otherPosition = ''
                }
            },

            /**
             * 上一题
             */
            prevClick() {
                this.$router.push('/location1')
            },

            /**
             * 下一题
             */
            nextClick() {
                if (this.outdoorPlace == '') {
                    this.$toast(this.$t('selectOne'));
                    return;
                }
                //选择其他时
                if (this.outdoorPlace == this.$t('outLocate9')) {
                    if (this.otherPosition == '') {
                        this.$toast(this.$t('chooseOtherTip'));
                        return;
                    } else {
                        let reg = /^[\u4e00-\u9fa5_a-zA-Z0-9]+$/
                        if (!reg.test(this.otherPosition)) {
                            this.$toast(this.$t('wordReg'));
                            return;
                        }
                    }
                } else {
                    this.otherPosition = ''
                }
                //保存答案
                this.$store.commit('SET_LOCATION3_ANSWER', [this.outdoorPlace, this.otherPosition])
                if (this.outdoorPlace === this.$t('outLocate1')) {
                    this.$router.push('/garden')
                } else {
                    this.$store.commit('SET_GARDEN_ANSWER', '')
                    this.$store.commit('CLEAR_ENTER')
                    this.$store.commit('SET_LEAVE1_ANSWER', '')
                    this.$router.push('/location4')
                }
            },

            /**
             * 验证码倒计时
             */
            countdown() {
                let that = this;
                this.countNumber = 2;
                if (this.timer) {
                    clearInterval(this.timer);
                }
                this.timer = setInterval(function () {
                    if (that.countNumber === 0) {
                        clearInterval(this.timer);
                    } else {
                        that.countNumber--;
                    }
                }, 1000);
            }
        }
    }
</script>

<style scoped lang="less">
    .location-back {
        height: 100%;

        /deep/ .el-radio {
            display: block;
            margin-bottom: calc(0.023 * var(--body-height));
        }
    }

</style>