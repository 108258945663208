import Vue from 'vue'
import VueI18n from 'vue-i18n'

import {Locale} from 'vant';
import enMessage from '../languages/en.json'
import enVant from 'vant/es/locale/lang/en-US'
import zhMessage from '../languages/zh.json'
import zhVant from 'vant/es/locale/lang/zh-CN';

Vue.use(VueI18n)

const messages = {
    English: {
        ...enMessage,
        ...enVant
    },
    Chinese: {
        ...zhMessage,
        ...zhVant
    }
}

// 注册i18n实例并引入语言文件，文件格式等下解析
const i18n = new VueI18n({
    locale: localStorage.userLang || 'Chinese',  //默认显示语言
    messages
})

if (i18n.locale === 'English') {
    Locale.use('en-US', enVant);
} else {
    Locale.use('zh-CN', zhVant);
}

export default i18n
