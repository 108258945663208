<template>
    <div class="rule-back">
        <div style="height:35px;"></div>
        <div class="title">《{{$t('ema')}}》</div>
        <!--标题-->
        <div class="title">{{$t('integralRule')}}</div>
        <!--内容-->
        <div class="content-box">
            <div class="rule-name">
                <span class="spot"></span>
                <span>{{$t('scoreMethod')}}</span>
            </div>
            <div class="content">
                <div v-html="ruleContent"></div>
            </div>
            <div class="rule-name">
                <span class="spot"></span>
                <span>{{$t('scoreUse')}}</span>
            </div>
            <div class="content">
                <div v-html="ruleUsage"></div>
            </div>
        </div>
        <!--返回-->
        <div class="back-row">
            <div @click="back" class="back-btn">{{$t('backToStart')}}</div>
        </div>
    </div>
</template>

<script>
    import api from '@/api'

    export default {
        name: "rule",
        data() {
            return {
                ruleContent: "",
                ruleUsage: ''
            }
        },
        mounted() {
            this.getRule();
        },
        methods: {

            getRule() {
                let lang = this.$i18n.locale ;
                api.questionnaire.getInfo(lang).then(res => {
                    this.ruleContent = res.data.result[0];
                    this.ruleUsage = res.data.result[1];
                })

            },

            back() {
                this.$router.push('/start')
            }

        }
    }
</script>

<style scoped lang="less">
    .rule-back {
        position: relative;
        box-sizing: border-box;
        padding: 0 4%;

        width: 100%;
        height: 100%;
        background-image: url("../../assets/img/ecoBack.png");
        background-size: cover;
    }

    .title {
        font-weight: bold;
        text-align: center;
    }

    .content-box {
        /*height: calc(100vh - 50px);*/
        height: calc(100% - 150px);
        overflow-y: scroll;
    }

    .rule-name {
        margin: 25px 0 15px 0;
        color: #3F75A8;
        font-size: 16px;
    }

    .spot {
        display: inline-block;
        width: 9px;
        height: 9px;
        border-radius: 50%;
        background-color: #3F75A8;
        margin-right: 9px;
    }

    .content {
        box-sizing: border-box;
        padding: 0 0 0 15px;
        color: #666;
    }

    @media (max-width: 300px) {
        .rule-name,
        .content {
            font-size: 14px;
        }
    }

    @media (min-width: 301px) {
        .rule-name,
        .content {
            font-size: 16px;
        }
    }

    .back-row {
        position: absolute;
        left: 0;
        bottom: 20px;
        text-align: center;
        width: 100%;

        .back-btn {
            position: absolute;
            bottom: calc(0.01 * var(--body-height));
            left: 50%;
            margin-left: -65px;
            width: 130px;
            height: 40px;
            background: #3F75A8;
            color: #fff;
            font-size: 16px;
            line-height: 40px;
            text-align: center;
            border-radius: 10px;
        }
    }


</style>