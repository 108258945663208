<template>
    <div class="notice-back">
        <div style="height:15px;"></div>
        <div class="big-title">{{$t('ema')}}</div>
        <div class="reward">{{$t('reward')}}：{{mark}} {{$t('score')}}、{{bean}} {{$t('bean')}}</div>
        <div class="notice-card">
            <div class="notice-content">
                <div v-html="noticeContent"></div>
            </div>
            <div class="check-box">
                <el-checkbox v-model="checked"
                             :disabled="countNumber!==0">{{$t('noticeTip')}}
                    <span v-show="countNumber">({{countNumber}}s)</span>
                </el-checkbox>
            </div>
            <div class="answer-btn">
                <el-button @click="toAnswerQues"
                           :disabled="!checked"
                           type="primary"
                           style="width:130px;">{{$t('toAnswer')}}
                </el-button>
            </div>
        </div>
    </div>
</template>

<script>
    import api from '@/api'
    import {mapGetters} from "vuex";

    export default {
        name: "index",
        data() {
            return {
                checked: false,
                countNumber: 3,
                mark: 0,
                bean: 0,
                noticeContent: ""
            }
        },
        computed: {
            ...mapGetters([
                'userId'
            ]),
        },
        mounted() {
            this.countdown();
            this.getReward();
            this.getNotice();
        },
        methods: {

            /**
             * 获取奖励
             */
            getReward() {
                api.questionnaire.getReward(this.userId).then(res => {
                    let arr = res.data.result;
                    this.mark = arr[0]
                    this.bean = arr[1]
                    this.$store.commit('SET_MARKS', arr[0])
                    this.$store.commit('SET_BEANS', arr[1])
                })
            },

            getNotice() {
                let lang = this.$i18n.locale ;
                api.questionnaire.getInfo(lang).then(res => {
                    this.noticeContent = res.data.result[2];
                })
            },

            /**
             * 去答题
             */
            toAnswerQues() {
                this.$router.push('/location1')
            },

            /**
             * 倒计时
             */
            countdown() {
                let that = this;
                this.countNumber = 2;
                if (this.timer) {
                    clearInterval(this.timer);
                }
                this.timer = setInterval(function () {
                    if (that.countNumber === 0) {
                        clearInterval(this.timer);
                        that.canSend = true;
                    } else {
                        that.countNumber--;
                    }
                }, 1000);
            }

        }
    }
</script>

<style scoped lang="less">
    .notice-back {
        width: 100%;
        height: 100%;
        background-image: url("../../assets/img/ecoBack.png");
        background-size: cover;

        .big-title {
            font-size: 25px;
            line-height: 25px;
            height: 50px;
            color: #333333;
            text-align: center;
            margin: 28px 0 13px 0;
        }

        .reward {
            font-size: 11px;
            color: #3F75A8;
            text-align: center;
            margin-bottom: 35px;
        }

        .notice-card {
            width: 100%;
            height: calc(100% - 156px);
            background: #FFFFFF;
            box-shadow: 0 0 15px 0 rgba(53, 53, 53, 0.17);
            border-radius: 25px 25px 0 0;
            box-sizing: border-box;
            padding: 20px 15px 0;

            .notice-content {
                background-image: url('../../assets/img/watermark.png');
                background-position: center 55%;
                background-repeat: no-repeat;
                background-size: 90%;
                /*height: calc(100vh - 320px);*/
                height: calc(100% - 151px);
                overflow-y: scroll;
            }

            .check-box {
                text-align: center;
                margin: 20px 0 12px;
            }

            .answer-btn {
                text-align: center;

            }
        }

    }

</style>