<template>
    <div class="log-back">
        <div class="log-head">
            <!--logo圈-->
            <div class="logo-circle">
                <img src="@/assets/img/heart.png" alt="">
            </div>
        </div>
        <div class="form-card">
            <div style="height:calc(0.071 * var(--body-height))"></div>
            <div class="app-name">{{$t('ema')}}</div>
            <div v-show="showFlag !== 3" class="change-btns-box">
                <div @click="toPhoneLog" :class="[changeBtnClass,{active:showFlag=== 1}]">{{$t('mobileLogin')}}</div>
                <div @click="toAccountLog" :class="[changeBtnClass,{active:showFlag=== 2}]">{{$t('accountLogin')}}</div>
            </div>
            <!--手机号登录-->
            <div v-show="showFlag === 1">
                <el-form :model="phoneForm"
                         :rules="phoneRules"
                         ref="phoneRef"
                         hide-required-asterisk>
                    <el-form-item prop="phoneNumber">
                        <el-input v-model="phoneForm.phoneNumber"
                                  maxlength="11"
                                  show-word-limit
                                  :placeholder="$t('phoneNum')">
                        </el-input>
                    </el-form-item>
                    <el-form-item prop="phoneCode">
                        <el-input v-model="phoneForm.phoneCode"
                                  maxlength="4"
                                  :placeholder="$t('code')">
                    <span @click.stop="loginSendCode('phoneRef')"
                          v-if="canSend"
                          slot="suffix"
                          class="send-code">{{$t('obtainCode')}}
                    </span>
                            <span v-else slot="suffix" class="seconds">{{countNumber}}s</span>
                        </el-input>
                    </el-form-item>
                </el-form>
                <el-button @click="phoneLogin" type="primary" class="logon-btn">{{$t('login')}}</el-button>
                <div class="log-tip">{{$t('noAccount')}}
                    <span @click="toRegister" class="to-register">{{$t('toRegister')}}</span>
                </div>
            </div>
            <!--账号登录-->
            <div v-show="showFlag === 2">
                <el-form :model="accountForm"
                         :rules="accountRules"
                         ref="accountRef"
                         hide-required-asterisk>
                    <el-form-item prop="userName">
                        <el-input v-model="accountForm.userName"
                                  maxlength="20"
                                  show-word-limit
                                  :placeholder="$t('account')">
                        </el-input>
                    </el-form-item>
                    <el-form-item prop="password">
                        <el-input v-model="accountForm.password"
                                  maxlength="16"
                                  show-password
                                  :placeholder="$t('password')">
                        </el-input>
                    </el-form-item>
                </el-form>
                <el-button @click="accountLogin" type="primary" class="logon-btn">{{$t('login')}}</el-button>
                <div class="log-tip">{{$t('noAccount')}} <span @click="toRegister" class="to-register">{{$t('toRegister')}}</span>
                </div>

            </div>
            <!--注册-->
            <div v-show="showFlag === 3">
                <el-form :model="registerForm"
                         :rules="registerRules"
                         ref="registerRef"
                         hide-required-asterisk>
                    <el-form-item prop="phoneNumber">
                        <el-input v-model="registerForm.phoneNumber"
                                  maxlength="11"
                                  show-word-limit
                                  :placeholder="$t('phoneNum')">
                        </el-input>
                    </el-form-item>
                    <el-form-item prop="phoneCode">
                        <el-input v-model="registerForm.phoneCode"
                                  maxlength="4"
                                  :placeholder="$t('code')">
                        <span @click.stop="registerSendCode('registerRef')"
                              v-if="canSend"
                              slot="suffix"
                              class="send-code">{{$t('obtainCode')}}
                        </span>
                            <span v-else slot="suffix" class="seconds">{{countNumber}}s</span>
                        </el-input>
                    </el-form-item>
                    <el-form-item prop="userName">
                        <el-input v-model="registerForm.userName"
                                  maxlength="20"
                                  show-word-limit
                                  :placeholder="$t('account')">
                        </el-input>
                    </el-form-item>
                    <el-form-item prop="password">
                        <el-input v-model="registerForm.password"
                                  maxlength="16"
                                  show-password
                                  :placeholder="$t('password')">
                        </el-input>
                    </el-form-item>
                    <el-form-item prop="confirmPassword">
                        <el-input v-model="registerForm.confirmPassword"
                                  maxlength="16"
                                  show-password
                                  :placeholder="$t('confirmPass')">
                        </el-input>
                    </el-form-item>
                </el-form>
                <el-button @click="registerLogin"
                           type="primary"
                           class="logon-btn">{{$t('registerAndLogin')}}
                </el-button>
                <div class="log-tip">{{$t('existAccount')}}
                    <span @click="registerToPhone" class="to-register">{{$t('toLogin')}}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import api from '@/api'
    import {mapActions} from 'vuex'

    export default {
        name: "index.vue",
        data() {
            return {
                //登录注册页面的显示
                //1手机号登录，2账号登录，3注册
                showFlag: 1,
                changeBtnClass: 'change-btn',

                //手机登录表单信息
                phoneForm: {
                    phoneNumber: '',
                    phoneCode: '',
                    codeToken: ''
                },
                //用户登录表单验证
                phoneRules: {
                    phoneNumber: [{validator: this.checkPhone, trigger: 'blur'}],
                    phoneCode: [{required: true, message: this.$t('enterCode'), trigger: 'blur'}]
                },
                //计时器
                timer: null,
                countNumber: 59,
                //是否可以发送验证码
                canSend: true,

                //账号登录表单
                accountForm: {
                    userName: '',
                    password: ''
                },
                accountRules: {
                    userName: [{validator: this.checkUserName, trigger: 'blur'}],
                    password: [{validator: this.checkLoginPass, trigger: 'blur'}]
                },

                //注册表单
                registerForm: {
                    phoneNumber: '',
                    phoneCode: '',
                    userName: '',
                    password: '',
                    confirmPassword: '',
                    roleName: 'personal'
                },
                registerRules: {
                    phoneNumber: [{validator: this.checkPhone, trigger: 'blur'}],
                    phoneCode: [{validator: this.checkCode, trigger: 'blur'}],
                    userName: [{validator: this.checkUserName, trigger: 'blur'}],
                    password: [{validator: this.checkPass, trigger: 'blur'}],
                    confirmPassword: [{validator: this.checkRePass, trigger: 'blur'}]
                }
            }
        },
        mounted() {
        },
        methods: {
            ...mapActions([
                'login',
                'phoneLog',
                'registLog'
            ]),

            /**
             * 手机登录
             */
            phoneLogin() {
                let that = this
                this.$refs.phoneRef.validate((valid) => {
                    if (valid) {
                        that.phoneLog(that.phoneForm).then(res => {
                            that.$router.push('/start')
                            that.clearTimer()
                        })
                    } else {
                        return false;
                    }
                });
            },

            /**
             * 注册发送验证码点击事件
             */
            registerSendCode(formRef) {
                let phone = this.registerForm.phoneNumber
                this.$refs[formRef].validateField('phoneNumber', (msg) => {
                    //手机号码验证通过
                    if (msg === '') {
                        api.loginApi.getCodeByPhone(phone).then(res => {
                            this.$message.info(this.$t('codeSent'))
                            this.canSend = false;
                            this.countdown()
                        })
                    }
                });
            },

            /**
             * 手机号登录发送验证码点击事件
             */
            loginSendCode(formRef) {
                let phone = this.phoneForm.phoneNumber
                this.$refs[formRef].validateField('phoneNumber', (msg) => {
                    //手机号码验证通过
                    if (msg === '') {
                        api.loginApi.isPhoneExist(phone).then(res => {
                            api.loginApi.getCodeByPhone(phone).then(res => {
                                this.$message.info(this.$t('codeSent'))
                                this.canSend = false;
                                this.countdown()
                                this.phoneForm.codeToken = res.data.result.slice(11)
                            })
                        })
                    }
                });
            },

            /**
             * 验证码倒计时60秒
             */
            countdown() {
                let that = this;
                this.countNumber = 59;
                if (this.timer) {
                    clearInterval(this.timer);
                }
                this.timer = setInterval(function () {
                    if (that.countNumber === 0) {
                        clearInterval(this.timer);
                        that.canSend = true;
                    } else {
                        that.countNumber--;
                        // console.log(that.countNumber);
                    }
                }, 1000);
            },

            /**
             * 清空计时器
             */
            clearTimer() {
                clearInterval(this.timer);
                this.timer = null
                this.canSend = true;
                this.countNumber = 59;
            },

            /**
             * 切换账号登录
             */
            toAccountLog() {
                this.showFlag = 2;
                this.$refs.phoneRef.resetFields();
                this.clearTimer();
            },

            /**
             * 去注册
             */
            toRegister() {
                this.showFlag = 3;
                this.$refs.phoneRef.resetFields();
                this.$refs.accountRef.resetFields()
                this.clearTimer();
            },

            /**
             * 验证手机号码
             */
            checkPhone(rule, value, callback) {
                let reg = /^0?(13[0-9]|15[012356789]|18[0-9]|14[578]|16[67]|17[035768]|19[012356789])[0-9]{8}$/;
                if (value === '') {
                    callback(new Error(this.$t('enterPhoneNum')))
                } else if (!reg.test(value)) {
                    callback(new Error(this.$t('enterRightPhone')))
                } else {
                    callback()
                }
            },

            /**
             * 验证四位数字验证码
             */
            checkCode(rule, value, callback) {
                let reg = /\b\d{4}\b/
                if (value === '') {
                    callback(new Error(this.$t('enterCode')))
                } else if (!reg.test(value)) {
                    callback(new Error(this.$t('codeFormat4')))
                } else {
                    callback()
                }
            },

            /**
             * 账号登录
             */
            accountLogin() {
                let that = this
                this.$refs.accountRef.validate((valid) => {
                    if (valid) {
                        that.login(that.accountForm).then(res => {
                            that.$router.push('/start')
                            that.clearTimer()
                        })
                    } else {
                        return false;
                    }
                });

            },

            /**
             * 切换到手机登录
             */
            toPhoneLog() {
                this.showFlag = 1;
                this.$refs.accountRef.resetFields()
            },

            /**
             * 验证是否存在空格
             */
            checkLoginPass(rule, value, callback) {
                if (value === '') {
                    callback(new Error(this.$t('enterPass')))
                } else if (value.indexOf(" ") !== -1) {
                    callback(new Error(this.$t('notEnterSpaces')))
                } else {
                    callback()
                }
            },

            /**
             * 注册并登录
             */
            registerLogin() {
                let that = this
                this.$refs.registerRef.validate((valid) => {
                    if (valid) {
                        that.registLog(that.registerForm).then(res => {
                            that.$router.push('/start')
                            that.clearTimer()
                            //添加用户详细信息
                            api.questionnaire.addUserDetail().then(res => {
                            })
                        })
                    } else {
                        return false;
                    }
                });
            },

            /**
             * 注册页面切换到手机登录
             */
            registerToPhone() {
                this.showFlag = 1;
                this.$refs.registerRef.resetFields();
                this.clearTimer();
            },

            /**
             * 验证用户名
             * @param rule
             * @param value
             * @param callback
             */
            checkUserName(rule, value, callback) {
                let reg = /^[0-9a-zA-Z_]+$/; //数字、字母、下划线
                if (value === '') {
                    callback(new Error(this.$t('enterAccount')))
                } else if (value.indexOf(" ") !== -1) {
                    callback(new Error(this.$t('notEnterSpaces')))
                } else if (!reg.test(value)) {
                    callback(new Error(this.$t('userAccountRule')))
                } else if (value.length < 6) {
                    callback(new Error(this.$t('accountMinLength')))
                } else {
                    callback()
                }
            },

            /**
             * 验证密码
             */
            checkPass(rule, value, callback) {
                let reg = /(?!^(\d+|[a-zA-Z]+|[~!@#$%^&*?]+)$)^[\w~!@#$%^&*?]{6,16}$/
                if (value === '') {
                    callback(new Error(this.$t('enterPass')));
                } else if (value.indexOf(" ") !== -1) {
                    callback(new Error(this.$t('notEnterSpaces')))
                } else if (!reg.test(value)) {
                    callback(new Error(this.$t('passRule')))
                } else {
                    if (this.registerForm.confirmPassword !== '') {
                        this.$refs.registerRef.validateField('confirmPassword');
                    }
                    callback();
                }
            },

            /**
             * 验证确认密码
             */
            checkRePass(rule, value, callback) {
                if (value === '') {
                    callback(new Error(this.$t('enterPass')));
                } else if (value.indexOf(" ") !== -1) {
                    callback(new Error(this.$t('notEnterSpaces')))
                } else if (value !== this.registerForm.password) {
                    callback(new Error(this.$t('twoPassNotMatch')));
                } else {
                    callback();
                }
            }


        }
    }
</script>

<style scoped lang="less">
    .log-back {
        height: 100%;
        /*padding: 4%;*/
        /*box-sizing: border-box;*/

        width: 100%;
        background-image: url("../../assets/img/startBack.png");
        background-size: cover;

        /deep/ .el-form-item__error {
            padding-top: 0;
        }

        /deep/ .el-form-item {
            /*margin-bottom: 20px;*/
            margin-bottom: 25px;
        }

        /deep/ .el-input__inner {
            height: 45px;
        }

        .send-code {
            font-weight: 500;
            font-size: 14px;
            color: #3F75A8;
            margin-right: 10px;
            line-height: 45px;
        }

        .seconds {
            color: #C0C4CC;
            font-size: 12px;
            margin-right: 10px;
        }

        .logon-btn {
            width: 100%;
            border-radius: 20px;
            margin: 0 0 calc(0.018 * var(--body-height));
        }

        .log-tip {
            font-size: 14px;
            color: #666;
            text-align: center;
        }

        .to-register {
            color: #1F9EFF;
            text-decoration: underline;
        }
    }

    .log-head {
        width: 100%;
        height: calc(0.15 * var(--body-height));
        position: relative;

        .logo-circle {
            position: relative;
            left: 50%;
            top: calc(0.04 * var(--body-height));
            margin-left: calc(-0.18 * var(--body-width));

            width: calc(0.36 * var(--body-width));
            height: calc(0.1662 * var(--body-height));
            background: #E4EDF8;
            box-shadow: 0 2px 5px 1px rgba(133, 166, 199, 0.27);
            border-radius: 50%;

            display: flex;
            justify-content: center;
            align-items: center;

            img {
                height: calc(0.08 * var(--body-height));
            }
        }

    }

    .form-card {
        width: 100%;
        height: calc(0.85 * var(--body-height));
        background: #E4EDF8;
        border-radius: 25px 25px 0 0;
        box-sizing: border-box;
        padding: 0 calc(0.04 * var(--body-width));

        .app-name {
            font-weight: bold;
            font-size: 25px;
            line-height: 25px;
            color: #333333;
            text-align: center;
            margin-bottom: calc(0.041 * var(--body-height));
        }

        .change-btns-box {
            width: 215px;
            height: 45px;
            background: rgba(63, 117, 168, .1);
            border-radius: 10px;
            margin: 0 auto calc(0.049 * var(--body-height));
            display: flex;
            box-sizing: border-box;
            padding: 5px;

            .change-btn {
                width: 100px;
                height: 35px;
                line-height: 35px;
                border-radius: 10px;
                color: #3F75A8;
                text-align: center;
            }

            .change-btn.active {
                color: #fff;
                background: #3F75A8;
            }
        }
    }

</style>
