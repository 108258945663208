import loginApi from "@/api/login";
import result from '@/api/result'
import questionnaire from "@/api/questionnaire";


/**
 * API出口文件
 */
export default {
    loginApi,
    result,
    questionnaire
}

