<template>
    <div></div>
</template>

<script>
    export default {
        name: "empty",
        data(){
            return{

            }
        },
        created() {
            window.parent.postMessage({ type: 'TOKEN_EXPIRED' }, '*');
        }
    }
</script>

<style scoped>

</style>