<template>
    <div class="location-back">
        <!--环图片-->
        <div class="img-box">
            <div class="prop-up"></div>
            <img src="@/assets/img/circle.png" class="circle" alt="">
        </div>
        <!--题目-->
        <div class="subject">
            <div>{{$t('enterTitle5')}}</div>
        </div>
        <!--选项-->
        <van-field v-model="amount"
                   :formatter="formatNum"
                   type="digit"
                   style="width:84%;margin:calc(0.073 * var(--body-height)) auto 0;"></van-field>
        <!--题目跳转-->
        <div class="subject-jump">
            <span @click="prevClick" class="prev-btn">{{$t('preQues')}}</span>
            <el-button @click="nextClick"
                       :disabled="countNumber!=0"
                       style="width:160px;"
                       type="primary">{{$t('nextQues')}} <i class="icon-right"></i> ({{countNumber}}s)
            </el-button>
        </div>
    </div>

</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        name: "garden_enter5",
        data() {
            return {
                //人数
                amount: '',
                timer: null,
                countNumber: 0
            }
        },
        computed: {
            ...mapGetters([
                'enter5Answer'
            ]),
        },
        watch: {
            '$route': {
                immediate: true, // 组件创建时立即触发
                handler(newVal, oldVal) {
                    this.$store.commit('SET_ANSWER_PERCENT', 0)
                }
            }
        },
        mounted() {
            this.countdown()
            this.amount = this.enter5Answer
        },
        methods: {

            /**
             * 格式化数值
             */
            formatNum(val) {
                if (val > 100) {
                    return 100
                } else if (val < 0) {
                    return 0
                } else {
                    return val;
                }
            },

            /**
             * 上一题
             */
            prevClick() {
                this.$router.push('/enter4')
            },

            /**
             * 下一题
             */
            nextClick() {
                if (this.amount == '') {
                    this.$toast(this.$t('selectOne'));
                    return;
                }
                //保存答案
                this.$store.commit('SET_ENTER5_ANSWER', this.amount)
                this.$router.push('/location4')
            },

            /**
             * 验证码倒计时
             */
            countdown() {
                let that = this;
                this.countNumber = 2;
                if (this.timer) {
                    clearInterval(this.timer);
                }
                this.timer = setInterval(function () {
                    if (that.countNumber === 0) {
                        clearInterval(this.timer);
                    } else {
                        that.countNumber--;
                    }
                }, 1000);
            }
        }
    }
</script>

<style scoped lang="less">
    .location-back {
        height: 100%;
    }

</style>