<template>
    <div class="location-back">
        <!--环图片-->
        <div class="img-box">
            <div class="prop-up"></div>
            <img src="@/assets/img/circle.png" class="circle" alt="">
        </div>
        <!--题目-->
        <div class="subject">
            <div>{{$t('leaveTitle1')}}</div>
        </div>
        <!--选项-->
        <van-field :value="duration"
                   @click="showPicker = true"
                   readonly
                   clickable
                   type="digit"
                   style="width:84%;margin:calc(0.073 * var(--body-height)) auto 0;"></van-field>
        <van-popup v-model="showPicker" position="bottom">
            <van-picker show-toolbar
                        :columns="columns"
                        @confirm="onConfirm"
                        @cancel="showPicker = false"
            />
        </van-popup>
        <!--题目跳转-->
        <div class="subject-jump">
            <span @click="prevClick" class="prev-btn">{{$t('preQues')}}</span>
            <el-button @click="nextClick"
                       :disabled="countNumber!=0"
                       style="width:160px;"
                       type="primary">{{$t('nextQues')}} <i class="icon-right"></i> ({{countNumber}}s)
            </el-button>
        </div>
    </div>

</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        name: "garden_leave1",
        data() {
            return {
                //时长
                duration: '',
                showPicker: false,
                columns: [
                    {
                        values: ['0小时', '1小时', '2小时', '3小时', '4小时', '5小时', '6小时'],
                        defaultIndex: 0,
                    },
                    {
                        values: ['10分钟', '20分钟', '30分钟', '40分钟', '50分钟'],
                        defaultIndex: 2,
                    }
                ],
                timer: null,
                countNumber: 0
            }
        },
        computed: {
            ...mapGetters([
                'leave1Answer'
            ]),
        },
        watch: {
            '$route': {
                immediate: true, // 组件创建时立即触发
                handler(newVal, oldVal) {
                    this.$store.commit('SET_ANSWER_PERCENT', 0)
                }
            }
        },
        mounted() {
            this.countdown()
            this.duration = this.leave1Answer
        },
        methods: {

            /**
             * 确认点击事件
             */
            onConfirm(value) {
                this.duration = value[0] + value[1];
                this.showPicker = false;
            },

            /**
             * 上一题
             */
            prevClick() {
                this.$router.push('/garden')
            },

            /**
             * 下一题
             */
            nextClick() {
                if (this.duration == '') {
                    this.$toast(this.$t('selectOne'));
                    return;
                }
                //保存答案
                this.$store.commit('SET_LEAVE1_ANSWER', this.duration)
                this.$router.push('/location4')
            },

            /**
             * 验证码倒计时
             */
            countdown() {
                let that = this;
                this.countNumber = 2;
                if (this.timer) {
                    clearInterval(this.timer);
                }
                this.timer = setInterval(function () {
                    if (that.countNumber === 0) {
                        clearInterval(this.timer);
                    } else {
                        that.countNumber--;
                    }
                }, 1000);
            }
        }
    }
</script>

<style scoped lang="less">
    .location-back {
        height: 100%;
    }

</style>